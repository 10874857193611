Lyte.Mixin.register("ledManageSubEvents", {
  subscribeTo: function (events, callbackFn) {
    const addDisposable = function (object, callee) {
      if (!this.hasOwnProperty("disposables")) {
        this.disposables = [];
      }
      if (typeof callee !== "object" || Array.isArray(callee)) {
        callee = {};
      }
      if (typeof object === "function") {
        this.disposables.push(
          Object.assign(
            {
              dispose: object
            },
            callee
          )
        );
      } else if (typeof object === "object") {
        this.disposables.push(object);
      }
    };
    if (
      _led.checkType.object(events)
    ) {
      for (let eventName in events) {
        const callback = events[eventName].bind(this);
        const disposable = this.getEditorInstance(
          this.getData("editorId")
        ).pubsub.subscribeTo(eventName, callback);
        disposable.eventName = eventName;
        addDisposable.call(this, disposable);
      }
    } else {
      const callback = callbackFn.bind(this);
      const objectOfDisposables = this.getEditorInstance(
        this.getData("editorId")
      ).pubsub.subscribeTo(events, callback);
      for (let key in objectOfDisposables) {
        const disposable = objectOfDisposables[key];
        addDisposable.call(this, disposable);
      }
    }
  },
  disposeSubscribedEvents: function () {

    (this.disposables || []).forEach(function (disposableObject) {
      if (disposableObject.argsArray && disposableObject.thisArg) {
        disposableObject.dispose.apply(
          disposableObject.thisArg,
          disposableObject.argsArray
        );
      } else {
        disposableObject.dispose();
      }
    });
    delete this.disposables;
  }.on("didDestroy"),
  disposeSubscribedEventByName: function (eventName) {
    (this.disposables || []).forEach(function (disposableItem, index) {
      if (disposableItem.eventName === eventName) {
        disposableItem.dispose();
        this.disposables.splice(index, 1);
      }
    });
  }
});

