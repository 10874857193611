Lyte.Mixin.register("ledManageUtils", {
  initializeUtils: function () {
    if (this.utils) {
      const utils = this.utils.call(this);
      const type = this.getEditorInstance(this.getData("editorId")).checkType;
      this.registeredUtils = {};
      for (let util in utils) {
        if (type.function(utils[util])) {
          utils[util] = utils[util].bind(this);
          this.$node[util] = utils[util];
        } else if (type.object(utils[util])) {
          // Only Single Level Grouping is currently supported
          this.$node[util] = {};
          for (let key in utils[util]) {
            utils[util][key] = utils[util][key].bind(this);
            this.$node[util][key] = utils[util][key];
          }
        } else {
          this.$node[util] = utils[util];
        }
        this.registeredUtils[util] = this.$node[util];
      }
    }
  }.on("didConnect"),
  disposeUtils: function () {
    if (this.registeredUtils) {
      const node = this.$node;
      for (let util in this.registeredUtils) {
        if (node[util].disposeUtil) {
          node[util].disposeUtil();
        }
        delete node[util];
      }
      delete this.registeredUtils;
    }
  }.on("didDestroy")
});
