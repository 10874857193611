/**
 * Creates and saves different paths which can be easily reused
 */
_led.addFeature('initializeGlobalPaths', function (basePath, cdnLink, fingerPrintMapping, fingerPrintPath) {
  const path = _led.path;
  const checkType = _led.checkType;
  const globalPath = {
    monaco: {},
    iframeEditor: {},
    miscellaneous: path.join(basePath, 'dependencies/miscellaneous')//No I18n
  };
  globalPath.monaco.baseFolder = path.join((cdnLink || path.join(basePath, '/dependencies/monaco-editor')), '/min/vs');//No I18n
  globalPath.monaco.iconFile = path.join(globalPath.monaco.baseFolder, '/base/browser/ui/codicons/codicon/codicon.ttf');//No I18n
  if( cdnLink ){
    globalPath.isCdn = true;
    globalPath.monaco.loaderFile = path.join( globalPath.monaco.baseFolder, 'loader.min.js');
  }else{
    globalPath.monaco.loaderFile = path.join( globalPath.miscellaneous, 'require.js');//No I18n
  }
  globalPath.iframeEditor.baseFolder = path.join(basePath, 'dependencies/lyte-editor');//No I18n
  if( fingerPrintPath ){
    globalPath.lyteEditorCss = checkType.isLink( fingerPrintMapping[ fingerPrintPath + 'css/lyte-editor.css' ] ) ?
      fingerPrintMapping[ fingerPrintPath + 'css/lyte-editor.css' ]
      : path.join(basePath, fingerPrintMapping[ fingerPrintPath + 'css/lyte-editor.css' ].replace( fingerPrintPath, "") );//No I18n

    globalPath.editorContextCss = checkType.isLink( fingerPrintMapping[ fingerPrintPath + 'css/editor-context.css' ] ) ?
      fingerPrintMapping[ fingerPrintPath + 'css/editor-context.css' ]
      : path.join(basePath, fingerPrintMapping[ fingerPrintPath + 'css/editor-context.css' ].replace( fingerPrintPath, "") );//No I18n

    globalPath.iframeEditor.html = checkType.isLink( fingerPrintMapping[ fingerPrintPath + 'dependencies/lyte-editor/editor.html' ] ) ?
      fingerPrintMapping[ fingerPrintPath + 'dependencies/lyte-editor/editor.html' ]
      : path.join(globalPath.iframeEditor.baseFolder, fingerPrintMapping[ fingerPrintPath + 'dependencies/lyte-editor/editor.html' ].replace( fingerPrintPath + 'dependencies/lyte-editor/', "") );//No I18n

    globalPath.iframeEditor.js = checkType.isLink( fingerPrintMapping[ fingerPrintPath + 'dependencies/lyte-editor/editor.js' ] ) ?
      fingerPrintMapping[ fingerPrintPath + 'dependencies/lyte-editor/editor.js' ]
      : path.join(globalPath.iframeEditor.baseFolder, fingerPrintMapping[ fingerPrintPath + 'dependencies/lyte-editor/editor.js' ].replace( fingerPrintPath+ 'dependencies/lyte-editor/', "") );//No I18n
  }else{
    globalPath.lyteEditorCss = path.join( basePath, '/css/lyte-editor.css');//No I18n
    globalPath.editorContextCss =  path.join( basePath, '/css/editor-context.css');//No I18n
    globalPath.iframeEditor.html = path.join(globalPath.iframeEditor.baseFolder, 'editor.html');//No I18n
    globalPath.iframeEditor.js = path.join(globalPath.iframeEditor.baseFolder, 'editor.js');//No I18n
  }

  _led.pathFor = _led.deepFreezer(globalPath);
});