/**
 * Will cache all the disposable objects and 
 * when triggered, will dispose all those objects by calling
 * the `.dispose` function
 */
_led.addFeature('createDisposablesHandler', function () {//No I18n
  const disposables = [];
  const addToDisposables = function (object) {
    disposables.push(object);
  }
  const disposeAllFuncs = function () {
    disposables.forEach(function (disposable) {
      disposable.dispose();
    });
  }
  return {
    add: addToDisposables,
    disposeAll: disposeAllFuncs
  }
});