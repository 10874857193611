Lyte.Mixin.register("ledLeftPanelUtils", {
  panelCompFinder: function (node, id) {
    return this.nodePicker(node, `[unique-id=${id}`);
  },
   nodePicker: function (node, selector) {
    return node.querySelector(selector);
  },
  lyteInputObserver: function (query) {
    this.nodePicker(this.$node,query).focus();
  },
  focusOutAction: function (inputEl, option) {
      inputEl.setData("ltPropValue", "");
      for (let key in option) {
        const value = option[key];
        this.setData(key, value);
      }
      this.resetErrorPopOver();
      return false;
    },
  makeScroll: function (childDom, scrollerDom, scrollDirec) {
    const direction = {
      horizontal: {
        direction: "scrollLeft",
        upper: "right",
        lower: "left",
        scrollAxisDimension: "offsetWidth",
        scrollDirection: "scrollLeft"
      },
      vertical: {
        direction: "scrollTop",
        upper: "bottom",
        lower: "top",
        scrollAxisDimension: "offsetHeight",
        scrollDirection: "scrollTop"
      }
    };
    const boundObjBcr = childDom.getBoundingClientRect();
    const parentDomBcr = scrollerDom.getBoundingClientRect();
    const upper = direction[scrollDirec].upper;
    const lower = direction[scrollDirec].lower;
    const boundObjUpper = boundObjBcr[upper];
    const boundObjLower = boundObjBcr[lower];
    const parentObjLower = parentDomBcr[lower];
    const childBottomFromParentTop = boundObjUpper - parentObjLower;
    const parentHeight =
      scrollerDom[direction[scrollDirec].scrollAxisDimension];
    let scrollBack;
    if (childBottomFromParentTop > parentHeight) {
      scrollBack = childBottomFromParentTop - parentHeight;
      scrollerDom[direction[scrollDirec].scrollDirection] += scrollBack;
    } else if (parentObjLower > boundObjLower) {
      scrollBack = boundObjLower - parentObjLower;
      scrollerDom[direction[scrollDirec].scrollDirection] += scrollBack;
    }
  },
  getItemId : function(){
    return this.getData('item').id;
  },
  getErrorColor : function(code){
    const cssColor = {
      409: "error",
      204: "error",
      404: "warning"
    };
    return cssColor[code] || '';
  },
  getEditorInstance: function (id) {
    return _led.getInstance(id);
  },
  methods: {
    
  }
});
