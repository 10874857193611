/**
 * Cache for Validators
 */
_led.addApi('registerValidator', function (language, validatorId, callback) {//No I18n
    if (!_led.checkType.string(validatorId)){
        _led.throw.warn(_led.error.EXPECTED, 'string', validatorId);//No I18n 
        return;
    }
    if (_led.checkType.string(language)) {
        if (_led.checkType.function(callback)) {
            _led._validators[language] = {validatorId: validatorId, doValidate: callback};
        } else {
            _led.throw.warn(_led.error.EXPECTED, 'function', callback);//No I18n
        }
    } else {
        _led.throw.warn(_led.error.EXPECTED, 'string', language);//No I18n 
    }
});