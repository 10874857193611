Lyte.Component.registerHelper('getI18nOf', function (string) {//No I18n
  if (Array.isArray(string)) {
    return string.map(function (item) {
      item = _led.changeCase.toSnake(item);
      return Lyte.Editor.I18n.getValueOf(item);
    });
  }
  string = _led.changeCase.toSnake(string);
  return Lyte.Editor.I18n.getValueOf(string);
});
Lyte.Component.registerHelper('lyteLeGetTabBarIconClass', function (data) {
  switch (data) {
    case "html": return "htmlIcon";
    case "css": return "cssIcon";
    case "js": return "jsIcon";
    case "java": return "javaIcon";
    case "py": return "pythonIcon";
    case "json": return "jsonIcon";
    case "jsp": return "jspIcon";
    case "db": return "dbIcon";
    case "properties": return "propIcon";
    case "xml": return "xmlIcon";
    case "sh": return "shIcon";
    case "md": return "mdIcon";
    case "txt" : return "txtIcon";
    case 'jpeg':
    case 'jpg' : 
    case 'gif' :
    case 'png' :
    case 'bmp' :
    case 'svg' :
    case 'webp':
    case 'ico' :
      return 'imageIcon';
    case 'mp4':
    case 'mkv':
    case 'mov':
    case 'webm':
      return 'videoIcon';
    case 'flac' : 
    case 'wav'  : 
    case 'mp3'  :
    case 'ogg'  :
    case 'm4a'  :
    case 'm4r'  :
      return 'audioIcon';
    case 'woff2': 
    case 'eot'  : 
    case 'ttf'  :
      return 'fontIcon';
    default: return "txtIcon";

  }
  
  return ""; //No I18N
});
Lyte.Component.registerHelper("lyteEditorMakeSpace", function (value) {
  return value.replace(/\s/g, "\u00A0");
});
Lyte.Component.registerHelper("lyteEditorCapitalize", function (value) {
  return _led.changeCase.toCapital(value);
});
Lyte.Component.registerHelper('getHeaderIcon', function (obj1,obj2) {//No I18n
  return obj1 || obj2;
});
