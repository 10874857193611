(function () {
    function getDataBlock(source, offset, stack) {
        let dataBlockSource = '';
        while (dataBlockSource.length < source.length/2 && source[offset] && stack.length > 0) {
            if (source[offset] === '{') {
                stack.push('{')
            } else if (source[offset] === '}') {
                stack.pop();
            }
            dataBlockSource += source[offset];
            offset++;
        }
        return dataBlockSource;
    }

    Lyte.Editor.RegisterValidator('javascript', 'slyteValidator', function(model){
        
        const DATA_TYPES = ['string', 'number', 'boolean', 'array', 'object'];
        let markers = [],
            value = model.getValue(),
            dataMatch = value.match(/data\s*\(\s*\)\s*{\s*return\s*{/);

        if (dataMatch === null) {return markers;}
        let offset = dataMatch.index + dataMatch[0].length,
            propsSourceCode = getDataBlock(value, offset, ['{']);
        let matches = propsSourceCode.matchAll(/:\s*prop\s*\(\s*('|")(\w+)('|")/g);
        matches = matches.toArray ? matches.toArray() : [];

        for (let i = 0; i < matches.length; i++) {
            if (!DATA_TYPES.includes(matches[i][2])) {
                let pos = model.getPositionAt(offset + matches[i].index + matches[i][0].match(/'|"/).index + 1);
                markers.push({
                    lineNumber: pos.lineNumber,
                    startColumn: pos.column,
                    endLineNumber: pos.lineNumber,
                    endColumn: pos.column + matches[i][2].length,
                    message: "Invalid data type. Accepted data types include 'string', 'number', 'boolean', 'array' and 'object'",
                    type: 'Error'
                })
            }
        }
        return markers;
    });
}());