window._scp = (function () {

  const ON = "on";
  const EMPTY = "";
  const TRUE = "true";
  const HTML = "html";
  const FALSE = "false";
  const JS = "javascript";
  const BOLLEAN = "boolean";
  const OPEN_MUSHTACH = "{{";
  const CLOSE_MUSHTACH = "}}";

  const text = "text";
  const tag = 'tag.html';
  const attributeNameType = "attribute.name.html";
  const attributeValue = "attribute.value.html";
  
  const NOT = '!';
  const DOT = '.';
  const EQUAL = '=';
  const QUOTE = '"';
  const COMMA = ',';
  const COLAN = ':';
  const SPACE = ' ';
  const DOLLAR = '$';
  const SEMI_COLON = ';';
  const LESS_THAN = '<';
  const OPEN_PAREN = '(';
  const CLOSE_PAREN = ')';
  const GREATER_THAN = '>';
  const OPEN_BRACKET = '{';
  const CLOSE_BRACKET = '}';
  const LITRAL_QUOTE = '\'';

  const TERMINATION_LITRALS = [
    NOT,
    DOT,
    '|',
    '\t',
    COLAN,
    COMMA,
    EQUAL,
    '&',
    SPACE,
    OPEN_PAREN,
    CLOSE_PAREN,
    '\n',
    OPEN_BRACKET,
    CLOSE_BRACKET,
    QUOTE,
    LITRAL_QUOTE,
    LESS_THAN,
    GREATER_THAN,
    '/',
    '[',
    ']'
  ];
  const COMPLETION_TOKENS = [
    'this',
    'self',
    '$node',
    'component',
    'actions',
    'methods'
  ];

  function isEqual(string, character) {
    return string === character;
  }

  function isSlyteExpression(codeStringAtLine, tokenLastIndex) {
    const slyteResult = {};
    while (tokenLastIndex >= 0) {
      if (isEqual(codeStringAtLine[tokenLastIndex], OPEN_BRACKET)) {
         if (isEqual(codeStringAtLine[tokenLastIndex-1], OPEN_BRACKET)) {
              slyteResult.mustache = true;
         }
         else{
              slyteResult.singleOpenBracket = true;
         }
         return slyteResult;
      }
      else if (isEqual(codeStringAtLine[tokenLastIndex],CLOSE_BRACKET) ) {
        return slyteResult;
      }
      tokenLastIndex--;
    }

    return slyteResult
  }
 
  function getTagName(codeStringAtLine, offset) {
    let tagName = EMPTY;

    while (codeStringAtLine[offset] && !/\s+/.test(codeStringAtLine[offset]) && !isEqual(codeStringAtLine[offset], GREATER_THAN) ) {
      tagName += codeStringAtLine[offset++];
    }

    return tagName;
  }

  function getAttributeName(model, codeStringAtLine, allTokens, lineNumber, index) {
    let offset;

    while ( lineNumber >=0 ) {
      if(index<0) {
        index = allTokens[lineNumber].length - 1;
      }

      while (allTokens[lineNumber][index]) {
        if (allTokens[lineNumber][index].type === attributeNameType) {
          offset = model.getOffsetAt( {lineNumber: lineNumber + 1, column: allTokens[lineNumber][index].offset + 1});
          break;
        }
        index--;
      }

      if (offset !== undefined) {
        break;
      }

      lineNumber--;
    }

    if (!offset) { return EMPTY; }

    let attributeName = EMPTY;

    while (codeStringAtLine[offset] && !/\s+/.test(codeStringAtLine[offset]) && !isEqual(codeStringAtLine[offset], EQUAL) ) {
      attributeName += codeStringAtLine[offset++];
    }

    return attributeName;
  }

  function addSlyteExpression(insertText, insertSlyteExpression) {
    if (!insertSlyteExpression) {return insertText;}

    return OPEN_MUSHTACH + insertText + CLOSE_MUSHTACH;
  }

  function getSnippet(param, position) {
    return DOLLAR + OPEN_BRACKET + (position + 1) + COLAN + param.name + CLOSE_BRACKET
  }

  function getParametersString(params) {
    if (!params || params.length === 0) {
      return CLOSE_PAREN;
    }

    return COMMA + params.map(getSnippet).join(COMMA) + CLOSE_PAREN
  }

  function kebabize(componentProperty) { 
    return componentProperty.replace( /[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : EMPTY_STRING) + $.toLowerCase() );
  }

  function getComponentMethods(componentInfo, suggestions, insertSlyteExpression, onlyNames, isKebabize) {
    if (!componentInfo) {return;}

    if(componentInfo.methods) {
      componentInfo.methods.forEach(method => {
        let insertText = onlyNames ? ( isKebabize ? kebabize(method.name) + '="{{method(\'$0\')}}"': method.name) :
          addSlyteExpression("method('" + method.name + "'" + getParametersString(method.params), insertSlyteExpression);
        let completionItem = getCompletionItem(method.name, 2, insertText, 4);
        if (isKebabize) {
          completionItem.filterText = insertText;
        }
        suggestions.push( completionItem );
      });
    }
    
    if (componentInfo.importMixins && componentInfo.importMixins.length > 0) {
      componentInfo.importMixins.forEach(mixin => {
        if (!toAvoidCyclicImports.has(mixin)) {
          toAvoidCyclicImports.add(mixin);
          getComponentMethods(SLYTE_COMPLETIONS_DATA.mixins[mixin], suggestions, insertSlyteExpression, onlyNames, isKebabize);
        }
      });
    }
  }

  function isComponentMethod(componentInfo, methodName) {
    if(!componentInfo || !componentInfo.methods) {
      return false;
    }

    let method = componentInfo.methods.find(method => method.name === methodName);

    if(!method && componentInfo.importMixins && componentInfo.importMixins.length > 0) {
      componentInfo.importMixins.forEach(mixin => {
        if (!toAvoidCyclicImports.has(mixin)) {
          toAvoidCyclicImports.add(mixin);
          isComponentMethod(SLYTE_COMPLETIONS_DATA.mixins[mixin], methodName);
        }
      });
    }

    return method !== undefined;
  }

  function getComponentActions(componentInfo, suggestions, insertSlyteExpression, onlyNames) {
    if (!componentInfo) {return;}

    if(componentInfo.actions) {
      componentInfo.actions.forEach(action => {
        let insertText = onlyNames ? action.name: addSlyteExpression("action('" + action.name + "'" + getParametersString(action.params) , insertSlyteExpression);
        
        suggestions.push( getCompletionItem(action.name, 3, insertText, 4) );
      });
    }

    if (componentInfo.importMixins && componentInfo.importMixins.length > 0) {
      componentInfo.importMixins.forEach(mixin => {
        if (!toAvoidCyclicImports.has(mixin)) {
          toAvoidCyclicImports.add(mixin);
          getComponentActions(SLYTE_COMPLETIONS_DATA.mixins[mixin], suggestions, insertSlyteExpression, onlyNames);
        }
      });
    }
  }

  function getComponentActionsAndMethods(componentInfo, suggestions, insertSlyteExpression, onlyNames) {
    clearSet();
    getComponentActions(componentInfo, suggestions, insertSlyteExpression, onlyNames);
    clearSet();
    getComponentMethods(componentInfo, suggestions, insertSlyteExpression, onlyNames);
  }

  function getComponentProperties(componentInfo, suggestions, isKebabize, slyteExpression, type) {
    if(!componentInfo) {return;}

    if ( componentInfo.properties) {
      let attributes = componentInfo.properties;

      for (const attribute in attributes) {
        if (Object.hasOwnProperty.call(attributes, attribute) && (!type || attributes[attribute].type === type) ) {
          let defaultValue = attributes[attribute].defaultValue;
          let insertText = (isKebabize ? kebabize(attribute) + EQUAL + (defaultValue ? QUOTE + defaultValue + QUOTE: QUOTE + DOLLAR + 1 + QUOTE)
            :slyteExpression ? OPEN_MUSHTACH + attribute + CLOSE_MUSHTACH: attribute);
          let completionItem = getCompletionItem(attribute, 5, insertText, 4, attributes[attribute].description);
          if (isKebabize) {
            completionItem.filterText = insertText;
          }
          suggestions.push(completionItem);
        }
      }

      if (componentInfo.importMixins && componentInfo.importMixins.length > 0) {
        componentInfo.importMixins.forEach(mixin => {
          if (!toAvoidCyclicImports.has(mixin)) {
            toAvoidCyclicImports.add(mixin);
            getComponentProperties(SLYTE_COMPLETIONS_DATA.mixins[mixin], suggestions, isKebabize, slyteExpression, type);
          }
        });
      }
    }
  }

  function gethelpers(helpers, suggestions, slyteExpression) {
    addHelpers(GLOBAL_HELPERS, suggestions, slyteExpression);
    addHelpers(helpers, suggestions, slyteExpression);
  }

  function addHelpers(helpers, suggestions, slyteExpression) {
    helpers = Object.keys(helpers).map(helper => {
      if (!helpers[helper].params) {
        helpers[helper].params = [];
      }

      let helperSignature = helper + OPEN_PAREN + helpers[helper].params.map(getSnippet).join(COMMA) + CLOSE_PAREN;

      return {
        name: helper,
        description: (helpers[helper].description || EMPTY) + " Slyte Helper",
        insertText: slyteExpression ? OPEN_MUSHTACH + helperSignature + CLOSE_MUSHTACH : helperSignature
      };
    });

    helpers.forEach(helper => {
      suggestions.push( getCompletionItem(helper.name, 3, helper.insertText, 4, helper.description) );
    })
  }

  function getComponentAttributes(componentName, suggestions) {
    if (SLYTE_COMPLETIONS_DATA.components[componentName]) {
      clearSet();
      getComponentProperties(SLYTE_COMPLETIONS_DATA.components[componentName], suggestions, true);
      clearSet();
      getComponentMethods(SLYTE_COMPLETIONS_DATA.components[componentName], suggestions, false, true, true);
    }
  }

  function getComponentPropertiesAndHelpers(componentName, suggestions, slyteExpression, type) {
    if (SLYTE_COMPLETIONS_DATA.components[componentName]) {
      clearSet();
      getComponentProperties(SLYTE_COMPLETIONS_DATA.components[componentName], suggestions, false, slyteExpression, type);
    }

    gethelpers(SLYTE_COMPLETIONS_DATA.helpers, suggestions, slyteExpression);
  }

  function getComponents(suggestions ,callback) {
    let components = SLYTE_COMPLETIONS_DATA.components;

    for (const component in components) {
      if (Object.hasOwnProperty.call(components, component) ) {
        getComponentSyntaxes(components[component].templates, component, components[component].description, suggestions ,callback);
        // suggestions.push( getCompletionItem(component, 9, component, undefined, components[component].description) )
      }
    }
  }
  
  function getTabSpace() {
    return SPACE + SPACE + SPACE + SPACE
  }

  function toPascalCase(type) {
    type = type.charAt(0).toUpperCase() + type.slice(1);
    return type.replace( /[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? SPACE : EMPTY) + $);
  }

  function getComponentSyntaxes(templates, componentName, description, completionItems,afterTriggerCallback){
    if (!templates) {
      completionItems.push({
        label: componentName,
          kind: 9,
          filterText: componentName,
          insertText: componentName + GREATER_THAN + LESS_THAN + '/' + componentName + GREATER_THAN
      });
      return;
    }

    for (const type in templates) {
      if (Object.hasOwnProperty.call(templates, type) && type !== '_propsToWatch') {
        let syntax = templates[type];
        let label = type.search(/\s/) === -1 ? toPascalCase(type): type;
        let item;
        if(afterTriggerCallback){
            item =  {
              label: componentName + getTabSpace() + label,
              kind: 9,
              filterText: componentName,
              insertText: ''
            }
            afterTriggerCallback(item , jsonToHtml , syntax.html);
        }
        else{
            item = {
              label: componentName + getTabSpace() + label,
              kind: 9,
              filterText: componentName,
              insertText: jsonToHtml(syntax.html).substring(1)
            }
        }

        if (description) {
          item.detail = description;
        }

        completionItems.push(item);
      }
    }
  }

  function getAttributeOffset(source, offset) {
    let flag = false;

    while (source[offset]) {
      if (isEqual(source[offset], EQUAL) && flag) {
        offset--;
        break;
      }
      if (isEqual(source[offset], QUOTE) || isEqual(source[offset], LITRAL_QUOTE) ) {
        flag = true;
      }
      offset--;
    }
    return offset;
  }

  function hasActionOrMethod(source, offset) {
    let attributeValueOffset = getAttributeOffset(source, offset);

    return source.substring(attributeValueOffset, offset).search(/\{\{\s*(action|method\s*\()/) !== -1;
  }

  function getTagPosition(allTokens, lineNumber) {
    while (lineNumber >= 0) {
      let ind = allTokens[lineNumber] && allTokens[lineNumber].length - 1;
      while(ind >=0 && allTokens[lineNumber][ind] ) {
        if (allTokens[lineNumber][ind].type === tag) {
          return {lineNumber: lineNumber + 1, column: allTokens[lineNumber][ind].offset + 1};
        }
        ind--;
      }
      lineNumber--;
    }
  }

  function getPreviousToken(codeString, offset) {
    let token = EMPTY;

    while (codeString[offset] && (isEqual(codeString[offset], COMMA) || isEqual(codeString[offset], QUOTE)
      || isEqual(codeString[offset], LITRAL_QUOTE) || isEqual(codeString[offset], OPEN_PAREN) )) {offset--;}

    while (codeString[offset] && (!isEqual(codeString[offset], OPEN_BRACKET) && !isEqual(codeString[offset], SPACE) )) {
      token = codeString[offset--] + token;
    }
    return token;
  }

  function getGlobalAttributes(suggestions) {
    SLYTE_GLOBAL_ATRIBUTES.forEach(attribute => {
      suggestions.push( getCompletionItem(attribute.name, 13, attribute.insertText, 4) );
    });
  }

  function getCompletionItem(label, kind, insertText, insertTextRules, detail) {
    let completionItem = {
      label: label,
      kind: kind,
      insertText: insertText
    }

    if (insertTextRules !== undefined) {
      completionItem.insertTextRules = insertTextRules;
    }

    if (detail !== undefined) {
      completionItem.detail = detail;
    }

    return completionItem;
  }
  let componentName;
  function getComponentName(jsCodeString){
    let compNameMatch = jsCodeString.match(/(Lyte\s*.\s*Component\s*.\s*register\s*\((\s*"|'))([\w-]+)/);
    return (compNameMatch !== null ? compNameMatch[3]: componentName);
  }

  function camelize(componentProperty) {
    return componentProperty.replace( /-./g, x => x[1].toUpperCase() );
  }

  function getCompletions(model, position, triggerKind) {
    let tokenInfo = getTokenInfo.call(this, model, position, triggerKind.triggerCharacter);
    let suggestions = [];

    if (tokenInfo.type === tag) {
        getComponents(suggestions , function(item,jsonHtml , data){
            let editorCommandId = this.editorCommandId;
            if(!editorCommandId){
              const monaco = this.monaco;
              const editor = monaco.editor.getEditors().find( (eachEditor)=>{ return eachEditor.getModel() == model; } ) // UseCase Avoid use of getEditor callback and getMonaco callback because it can taken by this logic and monaco from providerCompletion
              if(editor){
                  const id  = editor.addCommand( 
                    "0", 
                      function(_,jsonHtml ,data , position){
                        const str = jsonHtml(data);
                        const lineNumber = position.lineNumber;
                        const column = position.column;
                        editor.executeEdits('' , [{
                          range : new monaco.Range(lineNumber,column,lineNumber,column),
                          text : str.substring(1)
                        }])
                  } ,'' );
                this.editorCommandId = editorCommandId = id;
              } 
            }
            item.command = {
              id: editorCommandId,
              title: "triggerJsonHtml",
              arguments: [jsonHtml ,data , position]
            }
      }.bind(this));
      return suggestions;
    }

    if (tokenInfo.type === attributeNameType ) {
      getGlobalAttributes(suggestions)
      getComponentAttributes(tokenInfo.tagName, suggestions);
      return suggestions;
    }

    let compName = model.getValue().match(/(tag-name\s*=\s*"|')([\w-]+)/);
    compName = compName !== null ? compName[2]: undefined;
     
    
    if(mustacheCompletionProvider &&  (tokenInfo.mustache)){
            tokenInfo.codeString = model.getValue();
            let optionalDatas ={};
            const optionalDataKeys = ["attributeName" , "compData"];
            optionalDatas = optionalDataKeys.reduce(function(acc,eachKey){
                if(eachKey=="compData"){
                    if(tokenInfo.hasOwnProperty('tagName')){
                        acc[eachKey] = SLYTE_COMPLETIONS_DATA.components[tokenInfo.tagName];
                    }
                }
                else if(tokenInfo.hasOwnProperty(eachKey)){
                        acc[eachKey] = tokenInfo[eachKey];
                }
                return acc;
            } , optionalDatas);
            const mustacheSuggestion =  mustacheCompletionProvider(tokenInfo , {
              utils : {
                getComponentPropertiesAndHelpers , 
                createHelper : function(helper,description ,method,insertSlyteExpression){
                  const insertText =  addSlyteExpression("method('" + method.name + "$0'" + getParametersString(method.params), insertSlyteExpression);
                  return getCompletionItem(helper, 3, insertText, 4,description ||"Slyte Helper" ) 
                },
                isComponentMethod, 
                hasActionOrMethod
              } , 
              data : Object.assign( {
                currentLine : model.getLineContent(position.lineNumber)
              } , optionalDatas)
            });
            if(Array.isArray(mustacheSuggestion)){
                return mustacheSuggestion;
           }
    }
    if (tokenInfo.type === attributeValue) {
      if (triggerKind.triggerCharacter === DOT && (tokenInfo.slyteExpression || tokenInfo.mustache)) {
        return suggestions;
      }

      let attributeType;
      let attributeName = camelize(tokenInfo.attributeName);

      clearSet();
      if (SLYTE_COMPLETIONS_DATA.components[tokenInfo.tagName] && isComponentMethod(SLYTE_COMPLETIONS_DATA.components[tokenInfo.tagName], attributeName) && !tokenInfo.slyteExpression ) {
        if (!SLYTE_COMPLETIONS_DATA.components[compName]) {
          return suggestions;
        }
        if (!hasActionOrMethod(model.getLineContent(position.lineNumber), position.column) ) {
          clearSet();
          getComponentMethods(SLYTE_COMPLETIONS_DATA.components[compName], suggestions, !tokenInfo.mustache);
        } else {
          let offset = model.getOffsetAt({lineNumber: position.lineNumber, column: position.column - 1})
          let token = getPreviousToken(model.getValue(), offset);
          if (token === "method") {
            clearSet();
            getComponentMethods(SLYTE_COMPLETIONS_DATA.components[compName], suggestions, !tokenInfo.mustache, true);
          }
        }
        // return suggestions;
      }

      if (attributeName.startsWith(ON) && !tokenInfo.slyteExpression ) {
        if (!SLYTE_COMPLETIONS_DATA.components[compName]) {return suggestions;}

        if (!hasActionOrMethod(model.getLineContent(position.lineNumber), position.column) ) {
          getComponentActionsAndMethods(SLYTE_COMPLETIONS_DATA.components[compName], suggestions, !tokenInfo.mustache);
          return suggestions;
        } else {
          let offset = model.getOffsetAt({lineNumber: position.lineNumber, column: position.column - 1})
          let token = getPreviousToken(model.getValue(), offset);
          if (token === "method") {
            clearSet();
            getComponentMethods(SLYTE_COMPLETIONS_DATA.components[compName], suggestions, !tokenInfo.mustache, true);
            return suggestions;
          } else if (token === "action") {
            clearSet();
            getComponentActions(SLYTE_COMPLETIONS_DATA.components[compName], suggestions, !tokenInfo.mustache, true);
            return suggestions;
          }
        }
      }

      let attributeInfo = SLYTE_COMPLETIONS_DATA.components[tokenInfo.tagName] && SLYTE_COMPLETIONS_DATA.components[tokenInfo.tagName].properties
       && SLYTE_COMPLETIONS_DATA.components[tokenInfo.tagName].properties[attributeName];
      attributeType = attributeInfo && attributeInfo.type;
      if (attributeInfo && !tokenInfo.slyteExpression) {
        if (attributeInfo.type.toLowerCase() === BOLLEAN) {
          suggestions.push( getCompletionItem(TRUE, 13, TRUE) );
          suggestions.push( getCompletionItem(FALSE, 13, FALSE) );
        } else if ( attributeInfo.allowedValues) {
          attributeInfo.allowedValues.forEach(value =>{
            suggestions.push( getCompletionItem(value, 13, value) );
          });
          return suggestions;
        } else if (attributeInfo.defaultValue) {
          suggestions.push( getCompletionItem(attributeInfo.defaultValue, 13, QUOTE + attributeInfo.defaultValue + QUOTE) );
        } 
      }
      if((triggerKind.triggerCharacter!=OPEN_BRACKET  && !tokenInfo.slyteExpression) || tokenInfo.mustache){
          getComponentPropertiesAndHelpers(compName, suggestions, !tokenInfo.mustache, attributeType);
      }
      return suggestions;
    }
    if (tokenInfo.mustache) {
      getComponentPropertiesAndHelpers(compName, suggestions, !tokenInfo.mustache);
    }
    return suggestions;
  }
  
  function getTokenInfo(model, position, triggerCharacter) {
    if (!model || !position || model.getLanguageId() !== HTML) {
      throw new Error("Invalid Arguments Provided.");
    }

    let tagOffset;
    let tokenInfo = {};
    let codeString =  model.getValue();
    let tokenLastIndex =  position.column==1 ? 0 : position.column-2 ;

    let allTokens = this.monaco.editor.tokenize(codeString, model.getLanguageId() );
    let tokens = allTokens[position.lineNumber - 1];

    if (triggerCharacter && isEqual(triggerCharacter, LESS_THAN) ) {
        const slyteExpressionResult = isSlyteExpression(codeString, tokenLastIndex);
        const isSlyteMustache = slyteExpressionResult.mustache;
        const singleBracket = slyteExpressionResult.singleOpenBracket;
        if(singleBracket){
            tokenInfo.slyteExpression = true;
        }
        if (isSlyteMustache) {
            tokenInfo.mustache = true;
        } else {
        tokenInfo.type = tag;
        return tokenInfo;
      }
    }

    let i = 0;
    while (tokens[i] && tokens[i].offset <= tokenLastIndex) {
      if (tokens[i].type === tag) {
        tagOffset = tokens[i].offset;
      }
      i++;
    }
    if(tokens.length==0){
        return tokenInfo;
    }
    tokenInfo.type = !tokens[i - 1].type ? text: tokens[i - 1].type;
    const offset = model.getOffsetAt({lineNumber: position.lineNumber, column: position.column -1})
    tokenInfo.offset = offset;
    const isSlyteExp = isSlyteExpression(codeString , offset);
    const mustache = isSlyteExp.mustache
    const singleBracket = isSlyteExp.singleOpenBracket;
     if(singleBracket){
            tokenInfo.slyteExpression = true;
      }
    if (mustache) {
        tokenInfo.mustache = true;
    }

    if (tokenInfo.type === attributeNameType || tokenInfo.type === attributeValue) {
      if (!tagOffset) {
        tagOffset = model.getOffsetAt(getTagPosition(allTokens, position.lineNumber - 2) );
      } else {
        tagOffset = model.getOffsetAt({lineNumber: position.lineNumber, column: tagOffset + 1});
      }
      tokenInfo.tagName = getTagName(codeString, tagOffset)
    }

    if (tokenInfo.type === attributeValue) {
      tokenInfo.attributeName = getAttributeName(model, codeString, allTokens, position.lineNumber - 1, i - 1);
    }

    return tokenInfo;
  }
  let hasPreviousToken = false;

  function getPreviousToken(source, offset){
    hasPreviousToken = false;
    offset = getPreviousTokenPosition(source, offset);
    return getToken(source, offset, true);
  }

  function getPreviousTokenPosition(source, offset){
    while ( source[offset] && !TERMINATION_LITRALS.includes(source[offset]) ) { offset--; }
    offset = removeOffsetFromTerminationLitral(source, offset);
    return offset;
  }

  function removeOffsetFromTerminationLitral(source, offset){
    let rParenBeforLparen = false;
    while ( TERMINATION_LITRALS.includes(source[offset]) ) { 
      if( source[offset] === CLOSE_PAREN){
        rParenBeforLparen = true;
      }
      if( source[offset] === CLOSE_BRACKET){
        break;
      }
      if (source[offset] === DOT || !rParenBeforLparen && source[offset] === OPEN_PAREN || source[offset] === COLAN || source[offset] === COMMA
        || source[offset] === EQUAL || source[offset] === OPEN_BRACKET) {
        hasPreviousToken = true;
      }
      offset--;
    }
    return offset;
  }

  function getToken(codeString, offset, isChaining) {
    if (!hasPreviousToken && isChaining){
      return;
    }
    let tokenName = '';
    let skipNumbers = !(codeString[offset + 1] === QUOTE || codeString[offset + 1] === LITRAL_QUOTE);
    while (codeString[offset] && codeString[offset].match(/\s+/) !== null) {offset--}
    while ( codeString[offset] && codeString[offset] !== DOT && codeString[offset] !== NOT && codeString[offset] !== COMMA  && codeString[offset] !== SEMI_COLON
        && codeString[offset].match(/\s+/) === null) {
        if ([SPACE, QUOTE, LITRAL_QUOTE, CLOSE_PAREN].includes(codeString[offset]) || skipNumbers && codeString[offset].match(/\[|\]|[0-9]/)) {
            skipNumbers = !(codeString[offset] === QUOTE || codeString[offset] === LITRAL_QUOTE);
            offset--;
            continue; 
        }
        if(codeString[offset] === LITRAL_QUOTE || codeString[offset] === QUOTE){
            skipNumbers = false;
          }
        if (codeString[offset] === OPEN_PAREN){
            offset = offset + Math.floor(tokenName.length/2);
            break;
        }
        tokenName = codeString[offset] + tokenName;
        offset--;
    }
    offset = offset + 2;
    return {offset: offset, name: tokenName};
  }

  function getCompletionItems(componentName, type){
    let suggestions = [];

    if (!DEFAULTS[type]) {return suggestions;}

    if (type === 'component') {
      clearSet();
      getComponentFunctions(componentName, 'components', suggestions);
    }

    for (const label in DEFAULTS[type]) {
      if (Object.hasOwnProperty.call(DEFAULTS[type], label)) {
        let item = DEFAULTS[type][label];
        suggestions.push(getCompletionItem(label, item.kind, item.insertText || label, item.snnipetOrText || 1));
      }
    }

    return suggestions;
  }

  function getCompletionItemsFromMixin(componentName, superType, suggestions, type){
    let importedMixins = SLYTE_COMPLETIONS_DATA[superType] && SLYTE_COMPLETIONS_DATA[superType][componentName]
      && SLYTE_COMPLETIONS_DATA[superType][componentName].importMixins || [];
    for (let i = 0; i < importedMixins.length; i++) {
      if (!toAvoidCyclicImports.has(importedMixins[i])) {
        toAvoidCyclicImports.add(importedMixins[i]);
        switch(type){
          case 'props':
            getProperties(importedMixins[i], 'mixins', suggestions);
            break;
          case 'functions':
            getComponentFunctions(importedMixins[i], 'mixins', suggestions);
            break;
          default:
            getComponentActionsOrMethods(importedMixins[i], type, 'mixins', suggestions);
            break;
        }
      }
    }
  }

  function getParams(parameters){
    if (!parameters) { return OPEN_PAREN + CLOSE_PAREN ; }
    let parameterNames = [];
    let params = parameters.length - 1;
    parameters.forEach( param => {
        parameterNames.push(DOLLAR + OPEN_BRACKET + (params--) + COLAN + param.name + CLOSE_BRACKET);
    });
    return OPEN_PAREN + parameterNames.join(', ') + CLOSE_PAREN;
  }

  function getComponentFunctions(componentName, superType, suggestions) {
    let functions = SLYTE_COMPLETIONS_DATA[superType] && SLYTE_COMPLETIONS_DATA[superType][componentName]
      && SLYTE_COMPLETIONS_DATA[superType][componentName].functions || [];

    for (let i = 0; i < functions.length; i++) {
      suggestions.push(getCompletionItem(functions[i].name, 3, functions[i].name + getParams(functions[i].params), 4));
    }
    
    getCompletionItemsFromMixin(componentName, superType, suggestions, 'functions')
  }

  function getComponentActionsOrMethods(componentName, type, superType, suggestions){
    let actionsOrMethods = SLYTE_COMPLETIONS_DATA[superType] && SLYTE_COMPLETIONS_DATA[superType][componentName]
      && SLYTE_COMPLETIONS_DATA[superType][componentName][type] || [];

    for (let i = 0; i < actionsOrMethods.length; i++) {
      suggestions.push(getCompletionItem(actionsOrMethods[i].name, 2, actionsOrMethods[i].name));
    }

    getCompletionItemsFromMixin(componentName, superType, suggestions);
  }

  function getProperties(componentName, superType, suggestions){
    let props = SLYTE_COMPLETIONS_DATA[superType] && SLYTE_COMPLETIONS_DATA[superType][componentName]
      && SLYTE_COMPLETIONS_DATA[superType][componentName].properties || {};

    props = Object.keys(props);
    for (let i = 0; i < props.length; i++) {
      suggestions.push(getCompletionItem(props[i], 9, props[i]));
    }

    getCompletionItemsFromMixin(componentName, superType, suggestions, 'props');
  }

  function getJsCompletions(model, position, triggerKind){
    let offset = model.getOffsetAt({lineNumber: position.lineNumber, column: position.column -1});
    let codeString = model.getValue();
    let token = getPreviousToken(codeString, offset);//getToken(codeString, --offset);

    if (!token) {return [];}

    let componentName = getComponentName(codeString);
    if (triggerKind.triggerCharacter === DOT || COMPLETION_TOKENS.includes(token.name) ) {
      if (token.name === COMPLETION_TOKENS[4] || token.name === COMPLETION_TOKENS[5]) {
        let suggestions = [];
        clearSet();
        getComponentActionsOrMethods(componentName, token.name, 'components', suggestions);
        return suggestions;
      }
      let type = token.name === "this" || token.name === "self"? 'component' : token.name;
      return getCompletionItems(componentName, type);
    } else if (triggerKind.triggerCharacter === QUOTE || triggerKind.triggerCharacter === LITRAL_QUOTE
			|| codeString[offset] === QUOTE || codeString[offset] === LITRAL_QUOTE) {
			let suggestions = [];
			if (token.name === "set" || token.name === "get" || token.name === "setData" || token.name === "getData") {
				clearSet();
				getProperties(componentName, 'components', suggestions);
			} else if (token.name === "prop") {
				SLYTE_COMPLETIONS_DATA.dataTypes.forEach(type => {
					suggestions.push(getCompletionItem(type, 16, type));
				})
			}
      return suggestions;
    }
    return [];
  }

  function convertChild(json, customHooks) {
    if (json.child) {
      return json.child.map(function (item) {return jsonToHtml(item, customHooks);}).join('');
    }
  }

  function addQuotes(string) {
    return '"' + string + '"';
  }

  function jsonToHtml(json, customHooks) {
    customHooks = customHooks || {
      handlers: {}
    };
    if (window["common-utils"].checkType().object(json)) {
      // Empty Elements - HTML 4.01
      var empty = ['area', 'base', 'basefont', 'br', 'col', 'frame', 'hr', 'img', 'input', 'isindex', 'link', 'meta', 'param', 'embed'];

      var attr = EMPTY;
      if (json.attr) {
        const appendQuotes = customHooks.addQuotes || addQuotes;
        attr = Object.keys(json.attr).map(function (key) {
          var value = json.attr[key];
          if (Array.isArray(value)) {
            value = value.join(SPACE);
          }
          return key + EQUAL + appendQuotes(value);
        }).join(SPACE);
        if (attr !== EMPTY) {
          attr = SPACE + attr;
        }
      }

      var child = convertChild(json, customHooks);
      
      if (json.node === 'element') {
        var tag = json.tag;
        const handler = (customHooks.handlers || {})[json.node];
        if (typeof handler === 'function') {
          const output = handler(json);
          if (output !== null) {
            return output;
          }
        }

        child = convertChild(json, customHooks);
        if (empty.indexOf(tag) > -1) {
          // empty element '<'
          return LESS_THAN + json.tag + attr + '/' + GREATER_THAN;
        }

        // non empty element '<'
        var open = LESS_THAN + json.tag + attr + GREATER_THAN;
        var close = LESS_THAN + '/' + json.tag + GREATER_THAN;
        child = child || EMPTY
        return open + child + close;
      }

      if (json.node === 'text') {
        return json.text;
      }

      if (json.node === 'html') {
        return json.html;
      }

      if (json.node === 'comment') {
        return '<!--' + json.text + '-->';
      }

      if (json.node === 'root') {
        const handler = (customHooks.handlers || {})[json.node];
        if (typeof handler === 'function') {
          const output = handler(json);
          if (output !== null) {
            return output;
          }
        }
        child = convertChild(json, customHooks);
        return child;
      }

    } else {
      throw new Error("Invalid value. Expected object");
    }
  }

  function clearSet() {
    if( toAvoidCyclicImports.size>0) {
      toAvoidCyclicImports.clear();
    }
  }

  function isValidateCompletionDataType (type){
    return !type || !!_scp.completionTypes[type];
  }

  const _scp = Object.freeze({
    dispose: function(){
      if (!Lyte.Editor.CompletionProvider || !Lyte.Editor.CompletionProvider.Dispose) {
        throw new Error("Completion provider is not registered, So can't be disposed.");
      }

      Lyte.Editor.CompletionProvider.Dispose(HTML, 'htmlSlyte');
      Lyte.Editor.CompletionProvider.Dispose(JS, 'jsSlyte');
    },
    registerSlyteCompletionProvider: function(checkForDesiredModel , callbacks){
      if (!Lyte.Editor.CompletionProvider || !Lyte.Editor.CompletionProvider.Register) {
        throw new Error("Please load the Lyte Editor first.");
      }
      if(checkForDesiredModel){
          COMPLETION_PROVIDER_CONFIG.templateConfig.htmlSlyte.checkForDesiredModel = checkForDesiredModel;
          COMPLETION_PROVIDER_CONFIG.jsConfig.jsSlyte.checkForDesiredModel = checkForDesiredModel;
      }
      if(callbacks){
        COMPLETION_PROVIDER_CONFIG.templateConfig.htmlSlyte.userCallbacks = callbacks;
      }

      Lyte.Editor.CompletionProvider.Register(HTML, COMPLETION_PROVIDER_CONFIG.templateConfig);
      Lyte.Editor.CompletionProvider.Register(JS, COMPLETION_PROVIDER_CONFIG.jsConfig);
    },
    setCompletionsData: function(completionsData, type, name) {
      if (!isValidateCompletionDataType(type)) {
        throw new Error("Invalid Completion Data Type Provided");
      }

      if (!type) {
        for (const type in this.completionTypes) {
          if (Object.hasOwnProperty.call(this.completionTypes, type) && completionsData[type]) {
            SLYTE_COMPLETIONS_DATA[type] = completionsData[type];
          }
        }
      } else if (type && !name) {
        SLYTE_COMPLETIONS_DATA[type] = completionsData;
      } else if (type && name) {
        if(SLYTE_COMPLETIONS_DATA[type] && !SLYTE_COMPLETIONS_DATA[type][name]) {
          SLYTE_COMPLETIONS_DATA[type][name] = {};
        }
        SLYTE_COMPLETIONS_DATA[type][name] = completionsData;
      }

      if (type === _scp.completionTypes.components && !SLYTE_COMPLETIONS_DATA.components.template) {
        Object.assign(SLYTE_COMPLETIONS_DATA.components, FRAMEWORK_SUPPORTED_TAGS);
      }
    },
    updateCompletionData: function(completionsData, type, name){
      if (!isValidateCompletionDataType(type)) {
        throw new Error("Invalid Completion Data Type Provided");
      }

      if (!type) {
        for (const type in this.completionTypes) {
          if (Object.hasOwnProperty.call(this.completionTypes, type) && completionsData[type]) {
            Object.assign(SLYTE_COMPLETIONS_DATA[type], completionsData[type]);
          }
        }
      } else if (type && !name) {
        Object.assign(SLYTE_COMPLETIONS_DATA[type], completionsData);
      } else if (type && name) {
        if(SLYTE_COMPLETIONS_DATA[type] && !SLYTE_COMPLETIONS_DATA[type][name]) {
          SLYTE_COMPLETIONS_DATA[type][name] = {};
        }
        Object.assign(SLYTE_COMPLETIONS_DATA[type][name], completionsData)
      } else {
        throw new Error("Invalid Arguments Provided");
      }
    },
    updateCompletions: function(jsCodeString){
      if (!window.LyteClient || LyteClient.getParsedInfo) {
        throw new Error("Please load the Client side compilation dependencies of Lyte properly");
      }

      let parsedInfo = LyteClient.getParsedInfo(jsCodeString);

      if (parsedInfo.intelliSenseObj){
        this.updateCompletionData(parsedInfo.intelliSenseObj);
      }
    },
    setComponentName: function(name) {
      componentName = name
    },
    setMustacheCompletionProvider: function(callBack) {
      if (typeof callBack !== 'function') {throw new Error("Invalid Argument.")}
      mustacheCompletionProvider = callBack;
    },
    completionTypes:{
      components: "components",
      helpers: "helpers",
      mixins: "mixins"
    }
  });
  let mustacheCompletionProvider;
  const COMPLETION_PROVIDER_CONFIG = {
    templateConfig: {
      htmlSlyte: {
        triggerCharacters: [LESS_THAN, QUOTE, COLAN, COMMA, DOT,OPEN_BRACKET],
        ignoreGloballyRegisteredCompletions: false,
        provideCompletionItems: function (model, position, triggerKind ) {
          const suggestions = getCompletions.call(this, model, position, triggerKind);
          return {
            suggestions:  suggestions
          }
          
        },
        resolveCompletionItem: function(completionItem) {
          return completionItem;
        }
      }
    },
    jsConfig: {
      jsSlyte: {
        triggerCharacters: [DOT, QUOTE, LITRAL_QUOTE],
        ignoreGloballyRegisteredCompletions: false,
        provideCompletionItems: function (model, position, triggerKind ) {
          return {
            suggestions: getJsCompletions.call(this, model, position, triggerKind)
          }
        },
        resolveCompletionItem: function(completionItem) {
          return completionItem;
        }
      }
    }
  };

  const toAvoidCyclicImports = new Set();

  const SLYTE_COMPLETIONS_DATA = {
    components: {},
    helpers: {},
    mixins: {},
		dataTypes: [ 'array', 'boolean', 'number', 'object', 'string' ]
  };

  const SLYTE_GLOBAL_ATRIBUTES = [
    {
      name: 'lyte-break', insertText: 'lyte-break'
    }, {
      name: 'lyte-case', insertText: 'lyte-case="$1"'
    }, {
      name: 'lyte-default', insertText: 'lyte-default'
    }, {
      name: 'lyte-else', insertText: 'lyte-else'
    }, {
      name: 'lyte-else-if', insertText: 'lyte-else-if="{{$1}}"'
    }, {
      name: 'lyte-for', insertText: 'lyte-for="{{${1:array}}} as ${2:item} ${0:index}"'
    }, {
      name: 'lyte-for-in', insertText: 'lyte-for-in="{{${1:object}}} as ${2:value} ${0:key}"'
    }, {
      name: 'lyte-if', insertText: 'lyte-if="{{$1}}"'
    }, {
      name: 'lyte-switch', insertText: 'lyte-switch="{{$1}}"'
    }
  ];
 
  const GLOBAL_HELPERS = Object.freeze({
    concat: {
      name: 'concat',
      params: [{
        name: 'value1'
      }, {
        name: 'value2'
      }],
      description: "Helper to concat two or more values."
    },
    debugger: {
      name: 'debugger',
      params: [],
      description: "Wrapper to support debugging(debugger; ) from the templates."
    },
    encAttr: {
      name: 'encAttr',
      params: [{
        name: 'attributeValue'
      }],
      description: ""
    },
    escape: {
      name: 'escape',
      params: [{
        name: 'attributeValue'
      }, {
        name: 'encodeForType'
      }],
      description: ""
    },
    if: {
      name: 'if',
      params: [{
        name: 'condition'
      }, {
        name: 'trueCase'
      }, {
        name: 'falseCase'
      }],
      description: "Helper to perform if check."
    },
    ifEquals: {
      name: 'ifEquals',
      params: [{
        name: 'componentPropertyName'
      }, {
        name: 'value'
      }],
      description: "Helper to check if two values are equal."
    },
    ifNotEquals: {
      name: 'ifNotEquals',
      params: [{
        name: 'componentPropertyName'
      }, {
        name: 'value'
      }],
      description: "Helper to check if two values are unequal"
    },
    lbind: {
      name: 'lbind',
      params: [{
        name: 'componentPropertyName'
      }, {
        name: ''
      }],
      description: "To Support DOM to data change"
    },
    log: {
      name: 'log',
      params: [{
        name: '...any'
      }],
      description: "Wrapper to support logging(console.log() ) from the templates."
    },
    negate: {
      name: 'negate',
      params: [{
        name: 'valueToNegate'
      }],
      description: "The helper will return either true or false, depending upon the 'valueToNegate'."
    },
    unbound: {
      name: 'unbound',
      params: [{
        name: 'componentPropertyName'
      }],
      description: `By default, Lyte component establishes a link between data and DOM. 
        Any changes in data will be rendered immediately in the DOM. In certain cases, 
        we might not need this linking. This can be achieved using the unbound helper.`
    },
    unescape: {
      name: 'unescape',
      params: [{
        name: 'componentPropertyName'
      }, {
        name: '[options]'
      }, {
        name: '[sanitizerInstance]'
      }],
      description: `Lyte Component renders the dynamic values (values provided within mustache {{ }}) as text value. 
        There might be the cases where we might have to print the dynamic value as HTML content.
        For that we can use the "unescape" helper.`
    }
  });

  const FRAMEWORK_SUPPORTED_TAGS = Object.freeze({
    "lyte-yield": {
      properties: {
        'yield-name': {
          type: 'string',
          description: 'For yielded syntax of a component'
        }
      },
      action: [],
      methods: []
    },
    "link-to": {
      properties: {
        'lt-prop-route': {
          type: 'string',
          description: 'Route name for transition'
        },
        'lt-prop-dp': {
          type: 'array',
          description: 'Dynamic params - StringArray e.g.(["one","two"])'
        },
        'lt-prop-qp': {
          type: 'object',
          description: 'Query param object'
        },
        'lt-prop-td': {
          type: 'object',
          description: 'Transition data(which will be pushed to the history data)'
        },
        'lt-prop-id': {
          type: 'string',
          description: 'To set the corresponding attribute to anchor tag generated by link-to'
        },
        'lt-prop-rel': {
          type: 'string',
          description: 'To set the corresponding attribute to anchor tag generated by link-to'
        },
        'lt-prop-class': {
          type: 'string',
          description: 'To set the corresponding attribute to anchor tag generated by link-to'
        },
        'lt-prop-style': {
          type: 'string',
          description: 'To set the corresponding attribute to anchor tag generated by link-to'
        },
        'lt-prop-title': {
          type: 'string',
          description: 'To set the corresponding attribute to anchor tag generated by link-to'
        },
        'lt-prop-custom': {
          type: 'string',
          description: 'This property can be used to create a specific element instead of anchor tag.'
        },
        'lt-prop-target': {
          type: 'string',
          description: 'To set the corresponding attribute to anchor tag generated by link-to'
        },
        'lt-prop-fragment': {
          type: 'string',
          description: 'Scroll to certain element on the page with an id.'
        },
        'lt-prop-start-from': {
          type: 'string',
          description: 'To refresh/begin transition from specified route. If transitioned to same route, lt-prop-refresh-route has priority.'
        },
        'lt-prop-refresh-route': {
          type: 'boolean',
          description: 'To refresh from specified route, when the current route is same as specified route. By default it is set to false.'
        }
      },
      action: [],
      methods: []
    },
    "template": {
      properties:{
        'is': {
          type: 'string',
          "defaultValue": "yield",
          "allowedValues": ['yield', "registerYield" , "component"],
          description: ''
        },
        'tag-name': {
          type: 'string',
          description: 'Name of the component'
        },
        'yield-name': {
          type: 'string',
          description: 'Yield name for yielded syntax of a component'
        },
        "component-name": {
          type: 'string',
          description: 'Name of the component'
        },
        "lyte-keep-alive": {
          type: 'boolean',
          "defaultValue": "true",
          description: 'To keep alive old dynamically rendered component'
        }
      },
      action:[],
      methods:[]
    }
  });

  const DEFAULTS = { 
    component: {
      $node: { kind: 4},
      data: { kind: 8 },
      actions: { kind: 8 },
      methods: { kind: 8 },
      $lg: { kind: 8 },
      executeMethod: { kind: 2, snnipetOrText: 4, insertText: 'executeMethod("${1:methodName}, ${0:argument}")' },
      get: { kind: 2, snnipetOrText: 4, insertText: 'get($0)'},
      getData: { kind: 2, snnipetOrText: 4, insertText: 'getData($0)' },
      getMethods: { kind: 2, snnipetOrText: 4, insertText: 'getMethods("${0:methodName}")' },
      hasAction: { kind: 2, snnipetOrText: 4, insertText: 'executeMethod("${1:methodName}, ${0:argument}")' },
      set: { kind: 2, snnipetOrText: 4, insertText: 'get($0)'},
      setData: { kind: 2, snnipetOrText: 4, insertText: 'setData($1, ${0:value})' },
      setMethods: { kind: 2, snnipetOrText: 4, insertText: 'setMethods("${0:methodName}")' },
      throwEvent: { kind: 2, snnipetOrText: 4, insertText: 'throwEvent("${1:eventName}", ${0:argument})' }
    },
    $node: {
      component: { kind: 4 },
      attributes: {kind: 3 },
      classList: {kind: 3 },
      className: {kind: 3},
      clientHeight: {kind: 3 },
      clientLeft: {kind: 3 },
      clientTop: {kind: 3},
      clientWidth: {kind: 3 },
      id: {kind: 3 },
      localName: {kind: 3},
      namespaceURI: {kind: 3 },
      onfullscreenchange: {kind: 2, snnipetOrText: 4, insertText: 'onfullscreenchange((${1:element}, ${0:event})=>{})' },
      onfullscreenerror: {kind: 2, snnipetOrText: 4, insertText: 'onfullscreenerror(${1:element}, ${0:event})=>{})'},
      outerHTML: {kind: 3 },
      ownerDocument: {kind: 3 },
      part: {kind: 3},
      prefix: {kind: 3 },
      scrollHeight: {kind: 3 },
      scrollLeft: {kind: 3},
      scrollTop: {kind: 3 },
      scrollWidth: {kind: 3 },
      shadowRoot: {kind: 3},
      slot: {kind: 3 },
      tagName: {kind: 3 },
      attachShadow: {kind: 2, snnipetOrText: 4, insertText: 'attachShadow(${0:init})' },
      closest: {kind: 2, snnipetOrText: 4, insertText: 'closest(${0:selector})'},
      getAttribute: { kind: 2, snnipetOrText: 4, insertText: 'getAttribute(${0:qualifiedName})' },
      getAttributeNS: { kind: 2, snnipetOrText: 4, insertText: 'getAttributeNS(${0:namespace}, ${1:localName})' },
      getAttributeNames: { kind: 2, snnipetOrText: 4, insertText: 'getAttributeNames()' },
      getAttributeNode: { kind: 2, snnipetOrText: 4, insertText: 'getAttributeNode(${0:qualifiedName})' },
      getAttributeNodeNS: { kind: 2, snnipetOrText: 4, insertText: 'getAttributeNodeNS(${0:namespace}, ${1:localName})' },
      getBoundingClientRect: { kind: 2, snnipetOrText: 4, insertText: 'getBoundingClientRect()'},
      getClientRects: { kind: 2, snnipetOrText: 4, insertText: 'getClientRects()'},
      getElementsByClassName: { kind: 2, snnipetOrText: 4, insertText: 'getElementsByClassName(${0:classNames})' },
      getElementsByTagName: { kind: 2, snnipetOrText: 4, insertText: 'getElementsByTagName(${0:qualifiedName})'},
      getElementsByTagNameNS: { kind: 2, snnipetOrText: 4, insertText: 'getElementsByTagNameNS()' },
      hasAttribute: { kind: 2, snnipetOrText: 4, insertText: 'hasAttribute(${0:qualifiedName})' },
      hasAttributeNS: { kind: 2, snnipetOrText: 4, insertText: 'hasAttributeNS(${0:namespace}, ${1:localName})' },
      hasAttributes: { kind: 2, snnipetOrText: 4, insertText: 'hasAttributes()'},
      hasPointerCapture: { kind: 2, snnipetOrText: 4, insertText: 'hasPointerCapture(${0:pointerId})'},
      insertAdjacentElement: { kind: 2, snnipetOrText: 4, insertText: 'insertAdjacentElement(${0:where}, ${1:element})' },
      insertAdjacentHTML: { kind: 2, snnipetOrText: 4, insertText: 'insertAdjacentHTML(${0:position}, ${1:text})'},
      insertAdjacentText: { kind: 2, snnipetOrText: 4, insertText: 'insertAdjacentText(${0:where}, ${1:data})'},
      matches: { kind: 2, snnipetOrText: 4, insertText: 'matches(${0:selectors})' },
      querySelector: { kind: 2},
      querySelectorAll: { kind: 2},
      releasePointerCapture: {kind: 2, snnipetOrText: 4, insertText: 'releasePointerCapture(${0:pointerId})'},
      removeAttribute: { kind: 2, snnipetOrText: 4, insertText: 'removeAttribute(${0:qualifiedName})'},
      removeAttributeNS: { kind: 2, snnipetOrText: 4, insertText: 'removeAttributeNS(${0:namespace}, ${1:localName})' },
      removeAttributeNode: { kind: 2, snnipetOrText: 4, insertText: 'removeAttributeNode(${0:attr})'},
      requestFullscreen: { kind: 2, snnipetOrText: 4, insertText: 'requestFullscreen(${0:options?})' },
      requestPointerLock: { kind: 2, snnipetOrText: 4, insertText: 'requestPointerLock()'},
      scroll: { kind: 2, snnipetOrText: 4, insertText: 'scroll(${0:options?})' },
      scrollBy: { kind: 2, snnipetOrText: 4, insertText: 'scrollBy(${0:options?})' },
      scrollIntoView: { kind: 2, snnipetOrText: 4, insertText: 'scrollIntoView(${0:arg?})'},
      scrollTo: { kind: 2, snnipetOrText: 4, insertText: 'scrollTo(${0:options?})'},
      setAttribute: { kind: 2, snnipetOrText: 4, insertText: 'setAttribute(${0:qualifiedName}, ${1:value})'},
      setAttributeNS: { kind: 2, snnipetOrText: 4, insertText: 'setAttributeNS(${0:namespace}, ${1:qualifiedName}, ${2:value})'},
      setAttributeNode: { kind: 2, snnipetOrText: 4, insertText: 'setAttributeNode(${0:attr})'},
      setAttributeNodeNS: { snnipetOrText: 4, insertText: 'setAttributeNodeNS(${0:attr})'},
      setPointerCapture: { kind: 2, snnipetOrText: 4, insertText: 'setPointerCapture(${0:pointerId})'},
      toggleAttribute: { kind: 2, snnipetOrText: 4, insertText: 'toggleAttribute(${0:qualifiedName}, ${1:force?})'},
      webkitMatchesSelector: { kind: 2, snnipetOrText: 4, insertText: 'webkitMatchesSelector(${0:selectors})' },
      addEventListener: { kind: 2, snnipetOrText: 4, insertText: 'addEventListener(${0:type}, ${1:listener}, ${2:options?})' },
      removeEventListener: { kind: 2, snnipetOrText: 4, insertText: 'removeEventListener(${0:type}, ${1:listener}, ${2:options?})' },
      get: { kind: 2, snnipetOrText: 4, insertText: 'get($0)'},
      getData: { kind: 2, snnipetOrText: 4, insertText: 'getData($0)' }, 
      set: { kind: 2, snnipetOrText: 4, insertText: 'set(${2:someObject}, ${1:key}, ${0:value})' },
      setData: { kind: 2, snnipetOrText: 4, insertText: 'setData($1, ${0:value})' }
    }
  }

  return _scp;
}() );