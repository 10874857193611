/**
 * Some enumerators used by the lyte-editor can be modified
 * in run time. 
 * This feature is used for that purpose
 * 
 * Eventhough this is an internal feature, this will be added
 * to the Lyte.Editor 
 * 
 */
 _led.addFeature('updateEnum', (function () {//No I18n
  const updatableEnums = {
    //ignorei18n_start
    LIGHT_THEME: 'vs',
    DARK_THEME: 'vs-dark',
    MONACO_SRC_LOADED: false,
    DARK_MODE_CLASS: 'darkMode',
    DIRECTORY_FOLDER_ORDER: [],
    MSG_TYPE: [
			"success",
			"failure"
		],
     LANGS : {
      "js" : "javascript",
      "html" : "html",
      "css" : "css",
      "json" : "json",
      "txt" : "text"
    },
		FILE_HOOKS: {
			ON_BEFORE_SAVE: 'ON_BEFORE_SAVE',
			ON_SAVE: 'ON_SAVE',
			ON_RENAME: 'ON_RENAME',
			ON_MODEL_UPDATE: 'ON_MODEL_UPDATE'
		},
		FOLDER_HOOKS: {
			ON_FILE_LIST_CHANGE: 'ON_FILE_LIST_CHANGE'
		},
    LYTE_FILE_TYPES: {
      HELPER: 'helper',
      MIXIN: 'mixin',
      COMPONENT: 'component',
      OUTPUT: 'dist'
    },
     LEFT_DIRECTORY_ERROR : {
        300 : 'Cannot {0} on {1} directory',
        400 : 'No Directory Present in this path {0}',
        204 : "A file or folder name must be provided",
        409 : `A file or folder '{0}' already exists at this location, Please choose a different name.`,
        404 : "Leading or trialing whitespaces detected in file or folder Name",
        500 : "could not {0} in '{1}' type we expect {2} type",
        501 : "Restrict {0} in this {1} '{2}'"
    },
     DIRMENU_ID : '$uniqueId',
    DIRMENUATTR_ID : 'menuitem-unique-id'
    //ignorei18n_end
  }
  const type = _led.checkType;
  const createReadOnlyProp = function (propName) {
    return (function (key) {
      Object.defineProperty(this, key, {
        get: function () {
          return _led.deepClone(updatableEnums[key]);
        },
        set: function () {
          return _led.throw.warning(_led.error.READ_ONLY(key));
        }
      })
    }.call(this, propName));
  }
  const updateEnum = function (enumToUpdate, newValue) {
    if (type.string(enumToUpdate) && updatableEnums.hasOwnProperty(enumToUpdate)) {
      updatableEnums[enumToUpdate] = newValue;
    } else {
      _led.throw.error(_led.error.NOT_IN_ALLOWED_VALUES('enumToUpdate', Object.keys(updatableEnums), enumToUpdate));//No I18n
    }
  }
  const enumsToExpose = []
  for (let key in updatableEnums) {
    createReadOnlyProp.call(_led, key);
    if (window.Lyte && window.Lyte.Editor) {
      createReadOnlyProp.call(Lyte.Editor, key);
    } else {
      enumsToExpose.push(key);
    }
  }
  _led.exposeEnumsInLyteEditor = function () {
    enumsToExpose.forEach(function (key) {
      createReadOnlyProp.call(Lyte.Editor, key);
    });
    _led.defineProp.call(Lyte.Editor, _led.DESCRIPTOR_CODES[4], {
      UpdateEnum: updateEnum
    });
  }
  if (window.Lyte && window.Lyte.Editor) {
    _led.defineProp.call(Lyte.Editor, _led.DESCRIPTOR_CODES[4], {
      UpdateEnum: updateEnum
    });
  }
  return updateEnum;
}()));