Lyte.Component.register(
  "lyte-editor-tab-bar",
  {
_template:"<template tag-name=\"lyte-editor-tab-bar\"> <div class=\"leTabBar tab-bar-items\"> <template is=\"if\" value=\"{{makeRerender}}\"><template case=\"true\"> <template items=\"{{tabArray}}\" item=\"file\" index=\"index\" is=\"for\"><div class=\"leTabBarItem leCssVar {{sortableClass}} {{if(file.isActive,'active','')}}\" onclick=\"{{action('tabBarActivated',event,file,index)}}\" index=\"{{index}}\"> <span class=\"leTabBarFileType leIcon {{lyteLeGetTabBarIconClass(file.ext)}}\"> </span> <span class=\"leFileName\" onmouseover=\"{{action('showTitle',this,file.name,event)}}\"> <lyte-text lt-prop-value=\"{{file.name}}\" lt-prop-tooltip-config=\"{{tooltipConfig}}\"></lyte-text> <template is=\"if\" value=\"{{file.showFolder}}\"><template case=\"true\"><lyte-text lt-prop-tooltip-config=\"{{tooltipConfig}}\" lt-prop-value=\"{{file.path}}\" class=\"leFolderName\"> </lyte-text></template></template> </span> <span class=\"leIcon leStarIcon {{if(file.isDirty,'','hideIcon')}}\"> * </span> <span class=\"leIcon xIcon\" onclick=\"{{action('xIconClicked',event,file,index)}}\"> </span> </div></template> </template></template> </div> <template is=\"if\" value=\"{{expHandlers(tabArray.length,'>',0)}}\"><template case=\"true\"><div class=\"tab-bar-tool\"> <span class=\"threeDot-icon tbar-icon--center leIcon h-threedot\"></span> </div></template></template> <lyte-menu lt-prop-query=\".threeDot-icon\" lt-prop-user-value=\"name\" lt-prop-freeze=\"true\" lt-prop-content=\"{{rightSideTools}}\" lt-prop-event=\"click\" on-menu-click=\"{{method('rightSideToolClicked')}}\"></lyte-menu> </template>",
_dynamicNodes : [{"type":"attr","position":[1,1]},{"type":"if","position":[1,1],"cases":{"true":{"dynamicNodes":[{"type":"attr","position":[1]},{"type":"for","position":[1],"dynamicNodes":[{"type":"attr","position":[0]},{"type":"attr","position":[0,1]},{"type":"attr","position":[0,3]},{"type":"attr","position":[0,3,1]},{"type":"componentDynamic","position":[0,3,1]},{"type":"attr","position":[0,3,3]},{"type":"if","position":[0,3,3],"cases":{"true":{"dynamicNodes":[{"type":"attr","position":[0]},{"type":"componentDynamic","position":[0]}]}},"default":{}},{"type":"attr","position":[0,5]},{"type":"attr","position":[0,7]}]}]}},"default":{}},{"type":"attr","position":[3]},{"type":"if","position":[3],"cases":{"true":{"dynamicNodes":[]}},"default":{}},{"type":"attr","position":[5]},{"type":"componentDynamic","position":[5]}],
_observedAttributes :["ltPropData","tabArray","activeFile","activeItemIndex","sortableClass","isPopulateDirectory","makeRerender","allowActiveItemObs","rightSideTools"],

    data: function () {
      return {
        //ignorei18n_start
        ltPropData: Lyte.attr("object", { default: {} }),
        tabArray: Lyte.attr("array", { default: [] }),
        activeFile: Lyte.attr("string"),
        activeItemIndex: Lyte.attr("number",{default:-1}),
        sortableClass: Lyte.attr("string", { default: "" }),
        isPopulateDirectory: Lyte.attr("boolean", { default: true }),
        makeRerender: Lyte.attr("boolean", { default: true }),
        allowActiveItemObs : Lyte.attr('boolean',{
          default : true
        }),
        rightSideTools : Lyte.attr('array')
        //ignorei18n_end
      };
    },
    init : function(){
        this.setData('rightSideTools',[{  //No I18n
          name : 'Close All'  //No I18n
        }]);
    },
    indexFinderById: function (tabArray, id) {
      const val = tabArray.findIndex((eachEl, indx) => {
        return eachEl.uniqueId === id;
      });
      return val;
    },
    didConnect: function () {
      const node = this.$node;
      node.setTabAsActive = function (fileName, fileData) {
        const type = _led.checkType;
        const tabArray = this.getData("tabArray");
        let tabLen = tabArray.length;
        const data = this.getData("ltPropData");
        if (type.string(fileName) && type.object(fileData)) {
          const path = fileData.uniqueId;
          let indexVal = this.component.indexFinderById(tabArray, path);
          if (!data.hasOwnProperty(path)) {
            Lyte.arrayUtils(tabArray, "push", fileData);
            tabLen = tabArray.length;
            indexVal = tabLen - 1;
            this.setData("isPopulateDirectory", false);
          }
          this.setData("activeItemIndex", indexVal);
          this.setData('tbActiveItem',path);
          const tabItem = this.querySelector(`[index='${indexVal}']`);
          // this.component.makeScroll.call(
          //   this.component,
          //   tabItem,
          //   indexVal,
          //   tabLen,
          //   "left",
          //   "right",
          //   "scrollLeft",
          //   "offsetWidth",
          //   tabItem.parentElement
          // );
          this.component.makeScroll(
            tabItem,
            tabItem.parentElement,
            "horizontal"
          );
          this.component.checkForAmbiguities();
          this.component.updatePanelActiveItem();
          data[path] = fileData;
          this.setData("ltPropData", data);
          if (data[path]) {
            _led.devLog.info(
              fileName + "exists in tab bar and value is updated"
            ); //No I18N
          } else {
            _led.devLog.info(fileName + "is added in the tab bar"); //No I18N
          }
        } else {
          if (!type.string(fileName)) {
            _led.throw.error(_led.ERROR.TYPE_MUST_BE, "string", "fileName"); //No I18N
          }
          if (!type.object(fileData)) {
            _led.throw.error(_led.ERROR.TYPE_MUST_BE, "object", "fileData"); //No I18N
          }
        }
      };
      node.setTabAsDirty = function (filePath, value) {
        const type = _led.checkType;
        if (type.string(filePath)) {
          const tabArray = this.getData("tabArray");
          const data = this.getData("ltPropData");
          if (data.hasOwnProperty(filePath)) {
            Lyte.Component.set(data[filePath].content, "isDirty", value);
          } else {
            _led.devLog.warn(filePath + "is not present in tab bar"); //No I18N
          }
        } else {
          _led.throw.error(_led.ERROR.TYPE_MUST_BE, "string", "filePath"); //No I18N
        }
      };
      node.closeTab = function (filePath) {
        const type = _led.checkType;
        if (type.string(filePath)) {
          const tabArray = this.getData("tabArray");
          const data = this.getData("ltPropData");
          const fileData = data[filePath];
          delete data[filePath];
          Lyte.arrayUtils(tabArray, "removeObjects", fileData);
        } else {
          _led.throw.error(_led.ERROR.TYPE_MUST_BE, "string", "filePath"); //No I18N
        }
      };
      const RESETTABBAR  = "RESETTABBAR"; //No I18n
      this.subscribeTo(
        "DISPOSE_TABITEM",
        this.disposeTab.bind(this)
      );
      this.subscribeTo(
        "FORCERENDER",
        function () {
          this.setData("makeRerender", false);
          this.setData("makeRerender", true);
        }.bind(this)
      );
      this.subscribeTo(
      RESETTABBAR,
      this.resetTabBar.bind(this)
    );

      $L(".leTabBar").scroll();
      const self = this;
      $L(".leTabBar").sortable({
        orientation: "horizontal", //No I18n
        scrollDivX: ".leTabBar",
        containment: ".leTabBar",
        onDrop: function (
          droppedElement,
          destination,
          belowElement,
          fromIndex,
          toIndex,
          source
        ) {
          const tabArray = self.getData("tabArray");
          const sourceItem = tabArray[fromIndex];
          const destItem = tabArray[toIndex];
          let draggedElement;

          draggedElement = Lyte.arrayUtils(tabArray, "splice", fromIndex, 1);
          Lyte.arrayUtils(tabArray, "splice", toIndex, 0, draggedElement[0]);
          delete self.prevent;
          if (self.getData("activeItemIndex") === toIndex) {
            self.setData("activeItemIndex", fromIndex);
          }
          self.setActiveTab(tabArray[toIndex], toIndex);
        }
      });
      const sortableClass = this.$node
        .querySelectorAll(".leTabBar")[0]
        .getSortableClass();
      if (sortableClass) {
        this.setData("sortableClass", "sortable-element " + sortableClass);
      }
    },
    disposeTab: function (itemId) {
      const index = this.indexFinderById(this.getData("tabArray"), itemId);
      if (index != -1) {
        this.closeTabItem(index, this.getData("ltPropData")[itemId]);
      }
    },
    resetActiveItem() {
    this.setData("activeItemIndex", -1);
    this.setData("tbActiveItem","");
  },
    didDestroy: function () {
    },
    checkForAmbiguities: function () {
      const tabArray = this.data.tabArray;
      const showFolder = tabArray.reduce(function (acc, file) {
        if (acc[file.name] === undefined) {
          acc[file.name] = false;
        } else {
          acc[file.name] = true;
        }
        return acc;
      }, {});
      tabArray.forEach(function (file) {
        if (showFolder[file.name]) {
          Lyte.Component.set(file, "showFolder", true);
        } else {
          Lyte.Component.set(file, "showFolder", false);
        }
      });
    },
    getTabItemNode : function(indexVal){
        return this.$node.querySelector(`[index='${indexVal}']`);
    },
    constructTabArray: function () {
      const tabArray = this.getData("tabArray");
      const data = this.getData("ltPropData");
      for (let i in data) {
        const obj = {};
        obj.id = i;
        obj.content = data[i];
        this.checkForActiveFile(data[i], tabArray, i);

        Lyte.Component.set(tabArray, obj.id, data[i], null, null, true); // #
      }
    }.on("init"),
    checkForActiveFile: function (data, tabArray, fileName) {
      if (data.isActive) {
        if (this.getData("activeItemIndex") === undefined) {
          this.setData("activeItemIndex", tabArray.length);
          this.setData("activeFile", fileName);
        } else {
          this.setActiveFalse(tabArray, this.getData("activeItemIndex"));
          this.setData("activeItemIndex", tabArray.length);
          this.setData("activeFile", fileName);
        }
      }
    },
    // openDirectory: function () {
    //   if (this.getData("isPopulateDirectory")) {
    //     const tabArray = this.getData("tabArray");
    //     if (tabArray && tabArray.length) {
    //       const activeItem = tabArray[this.getData("activeItemIndex")];
    //       const id = activeItem.uniqueId;
    //       const directoryComp = this.panelCompFinder(document, id);
    //       // document.querySelector(`[unique-id=${id}]`);
    //       if (directoryComp) {
    //         directoryComp.component.throwEvent("openDirectoryUi", true);
    //         directoryComp.component.activateItem(
    //           directoryComp.querySelector("div"),
    //           "highLight"
    //         );
    //         const parent =
    //           activeItem.parentDir &&
    //           this.panelCompFinder(document, activeItem.parentDir);
    //         // document.querySelector(`[unique-id=${activeItem.parentDir}]`);
    //         const length = parent && parent.component.getChildren().length;
    //         const index = directoryComp.component.getData("index");
    //         if (index || index == 0) {
    //           const parentScroller = document.querySelector(".allDirectory");
    //           // this.checkScrollable.call(
    //           //   parent && parent.component,
    //           //   directoryComp,
    //           //   index,
    //           //   length,
    //           //   "top",
    //           //   "bottom",
    //           //   "scrollTop",
    //           //   "offsetHeight",
    //           //   parentScroller
    //           // );
    //           this.makeScroll(directoryComp, parentScroller, "vertical");
    //         }
    //       } else {
    //         this.disposeTab(id);
    //       }
    //     }
    //   } else {
    //     this.setData("isPopulateDirectory", true);
    //   }
    // },
    // checkScrollable: function (
    //   tabItemDom,
    //   indexVal,
    //   length,
    //   lower,
    //   upper,
    //   changingProp,
    //   dimension,
    //   parentDom
    // ) {
    //   // const elemOffsetWidth = tabItemDom[dimension]; //
    //   // const itemRect = tabItemDom.getBoundingClientRect();
    //   // const parentRect = parentDom.getBoundingClientRect();
    //   // this.executeMethod(
    //   //   "scrolling",
    //   //   elemOffsetWidth,
    //   //   {
    //   //     lowerBound: {
    //   //       value: itemRect[lower],
    //   //     },
    //   //     upperBound: {
    //   //       value: itemRect[upper],
    //   //     },
    //   //   },
    //   //   {
    //   //     lowerBound: {
    //   //       value: parentRect[lower],
    //   //     },
    //   //     upperBound: {
    //   //       value: parentRect[upper],
    //   //     },
    //   //   },
    //   //   parentDom,
    //   //   changingProp,
    //   //   {
    //   //     length: length,
    //   //     index: indexVal,
    //   //   }
    //   // );
    //   // childDom, scrollerDom, scrollDirec;
    //   this.executeMethod('makeScroll',tabItemDom,parentDom,"horizontal");
    // },
    updatePanelActiveItem : function () {
      const activeId = this.getData('activeItemIndex')!==-1 ? this.getActiveItem().uniqueId : '';
      this.setData("tbActiveItem", "");
      this.setData('tbActiveItem',activeId);
    },
    setActiveTrue: function (array, index, item) {
      array[index].activate();

      this.setData("activeItemIndex", index);
      if (this.getMethods("onTabItemActivated")) {
        this.executeMethod("onTabItemActivated", array[index]); //No I18n
      }
    },
    setActiveFalse: function (array, index) {
      array[index].deactivate();
    },
    setActiveTab: function (item, indexVal) {
      const activeItemIndex = this.data.activeItemIndex;
      const tabArray = this.getData("tabArray");
      if (activeItemIndex !== undefined) {
        if (activeItemIndex !== indexVal) {

          //check before file open
          const editorInstance = _led.getInstance(this.data.editorId);
          const hasBeforeOpen = editorInstance.getMethods('onBeforeFileOpen');
          const fileId = item.uniqueId;
          const modifiedFile = editorInstance.directory.getFileModelObj(fileId);
          if(hasBeforeOpen)
          {
          const result = editorInstance.executeMethod('onBeforeFileOpen',modifiedFile);
          if(result)
          {
            if(result instanceof Promise)
            {
                  result.then(function(){
                    this.setActiveFalse(tabArray, activeItemIndex);
                    this.setActiveTrue(tabArray, indexVal, item);
                    this.updatePanelActiveItem();
                    editorInstance.triggerMethod('onFileOpen',modifiedFile);
                  })
            }
            else{
                this.setActiveFalse(tabArray, activeItemIndex);
                this.setActiveTrue(tabArray, indexVal, item);
                this.updatePanelActiveItem();
                editorInstance.triggerMethod('onFileOpen',modifiedFile);
             }
          }
           
        }
        else{
              this.setActiveFalse(tabArray, activeItemIndex);
              this.setActiveTrue(tabArray, indexVal, item);
              this.updatePanelActiveItem();
              editorInstance.triggerMethod('onFileOpen',modifiedFile);
        }
          

          //call onopen callback
          
        }
        else{
        this.updatePanelActiveItem();
      } 
        // else {

        //   this.setData('activeItemIndex',-1);
        //   this.setData('activeItemIndex',activeItemIndex);
        // }
      }
    },
    closeTabItem: function (indexVal, file) {
      const self = this;
      
      const tabArray = self.data.tabArray;
      const index = self.data.activeItemIndex;
      const ltPropData = self.data.ltPropData;
      delete ltPropData[file.uniqueId];
      Lyte.arrayUtils(tabArray, "removeAt", indexVal);
      const tabArrayLength = tabArray.length;
      const editorInstance = _led.getInstance(self.data.editorId);
      if (tabArrayLength > 0) {
        if (index === indexVal) {
          if (index >= tabArray.length) {
            self.setActiveTrue(tabArray, tabArray.length - 1);
          } else if (indexVal == index) {
            let upcomingIndx;
            if (indexVal == 0) {
              upcomingIndx = 0;
            } else {
              upcomingIndx = indexVal - 1;
            }
            self.setActiveTrue(tabArray, upcomingIndx);
          }
         
        } else if (indexVal < index) {
          self.setData("activeItemIndex", index - 1);
        }

         this.updatePanelActiveItem();
        self.checkForAmbiguities();
      }

      editorInstance.directory.closeFile(file.uniqueId);
      editorInstance.triggerMethod("onFileClose",editorInstance.directory.getFileModelObj(file.uniqueId)); //No I18N
      if (tabArrayLength === 0) {
       this.resetActiveItem();
        editorInstance.getMethods("onAllFilesClosed") &&
        editorInstance.executeMethod("onAllFilesClosed"); //No I18n
        if(editorInstance.data.media) {
          editorInstance.setData("media", void 0);
        }
        editorInstance.setData("hideEditor", true);
      }
    },
     resetTabBar : function() {
    let tbArr = this.getData("tabArray"); //No I18n
    const self = this;
    tbArr = tbArr.slice();
    tbArr.forEach(function (eachTabItem) {
      const itemId = eachTabItem.uniqueId;
      self.closeTabItem(0, self.getData("ltPropData")[itemId]);
    });
  },
    getActiveItem : function(){
      return this.getData('tabArray')[this.getData('activeItemIndex')];
    },
    actions: {
      tabBarActivated: function (event, item, indexVal) {
        this.setActiveTab(item, indexVal);
      },
      // mouseenter: function (event, item) {
      //   item = item.querySelector(".xIcon");
      //   item.classList.remove("hideIcon");
      // },
      // mouseout: function (event, item) {
      //   item = item.querySelector(".xIcon");
      //   item.classList.add("hideIcon");
      // },
      showTitle: function (node, content, event) {
        const tooltipConfig = {
          position: "bottom",
          appearance: "box",
          showdelay: 100,
          hidedelay: 100,
          maxdisplaytime: 3000,
          margin: 10
        };
        if (node.scrollWidth > node.clientWidth) {
          node.setAttribute(
            "lt-prop-tooltip-config",
            JSON.stringify(tooltipConfig)
          );
          node.setAttribute("lt-prop-title", content);
        }
        event.preventDefault();
      },
      getFileDataByIndex: function (index) {
        return this.getData("tabArray")[index];
      },
      xIconClicked: function (event, file, indexVal) {
        const callback =
          this.getMethods("onBeforeTabClose") &&
          this.executeMethod("onBeforeTabClose", event, file);
        callback
          .then(
            function () {
              this.closeTabItem(indexVal, file);
            }.bind(this)
          )
          .catch(function (err) {
            console.log(err);
          });
        event.stopPropagation();
      }
    },
    methods : {
      rightSideToolClicked : function(toolObj){
         const toolClicked = toolObj.name;
         switch(toolClicked){
          case 'Close All':{
              this.resetTabBar();
          }
         }
      }
    },
    addListeners: function () {
      // const self = this;
      this.subscribeTo(
        "ADD_TO_TAB_BAR",
        function (fileName, file) {
          //No I18n
          this.$node.setTabAsActive(fileName, file);
        }.bind(this)
      );
      this.subscribeTo("CLOSE_FILE", function(uniqueId){
        const editorInstance = _led.getInstance(this.data.editorId);
        const file = editorInstance.folder.getDirectoryData(uniqueId);
        this.closeTabItem( this.indexFinderById(this.data.tabArray, uniqueId), file);
      });
    }.on("init")
  },
  {
    mixins: ["ledManageUtils", "ledLeftPanelUtils","ledManageSubEvents"] //No I18n
  }
);
