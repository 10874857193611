Lyte.Mixin.register("ledMakeActive", {
  activateItem: function (node, selector, id) {
    const editorInstance = _led.getInstance(this.getData('editorId'));
    const prevActive = editorInstance.$node.querySelector(".leActive");
    if(prevActive != node) {
      const fileObject = editorInstance.folder.getDirectoryData(id);
      if (prevActive) {
        prevActive.classList.remove("leActive");
      }
      node.classList.add("leActive");
      this.executeMethod("parentSetterCallback", id);
      editorInstance.triggerMethod('onDirActive', fileObject);
    }
  },
  populateDirectory(activeId, dataObj) {
    const scopeNode = (dataObj && dataObj.scopeNode) || this.$node;
    const directoryNode = this.panelCompFinder(scopeNode, activeId);
    const directoryItem = directoryNode.closest(
      "lyte-editor-directory-panel-item"
    ).component;
    directoryItem.createFileOpenModal(true);
    directoryItem.throwEvent("openDirectoryUi");
    return {
      comp: directoryItem,
      node: directoryNode
    };
  },
  modifyDirectory: function (fileId, newData) {
    return this.getEditorInstance(
      this.getData("editorId")
    ).directory.renameFile(fileId, newData);
  },

  crudOnDom: function (itemArray, option) {
    const position = option.position || "insertAt";
    switch (position) {
      case "insertAt": {
        const index = option.index || 0;
        const item = option.item;
        Lyte.arrayUtils(itemArray, position, index, item);
        break;
      }
      case "removeAt": {
        const targetIndx = option.index;
        const noOfEl = option.count;
        Lyte.arrayUtils(itemArray, position, targetIndx, noOfEl);
        break;
      }
    }
  },
  getParentComp: function () {
    const parentNode = this.$node.closest(
      `[unique-id=${this.getData("parentId")}]`
    );
    return parentNode.component;
  },
  createDirOnDom(createdItem, fileType, childItems, restrictActivate) {
    this.crudOnDom(childItems, {
      position: "insertAt",
      index: this.getFileIndex(createdItem.id),
      item: createdItem
    });
    this.data.sort == true && this.makeMeDraggable($L(this.panelCompFinder(this.$node, createdItem.id)));
    if (fileType == 'file') {
      const id = createdItem.id;
      this.activateItemById(createdItem.id);
      if (!restrictActivate) {
        this.throwEvent("populateAndActivateItem", createdItem.id);
        this.fileActivation(createdItem.id, 'leHighlight', this.nodePicker(this.$node, `[unique-id=${createdItem.id}]`));
      }

    }
    else {
      if (!restrictActivate) {
        this.populateDirectory(createdItem.id);
      }
      this.activateItemById(createdItem.id);
    }
  },

  createDirOnDataAndDom(nameValue, fileType, fileContent, childItems, toActivate) {
    const createdItem = this.executeMethod(
      "createFile",
      fileType,
      nameValue,
      fileContent
    );
    this.createDirOnDom(createdItem, fileType, childItems, toActivate);
    return createdItem;
  },

  makeNextActive: function (isFile, currentIndex) {
    const childItems = this.getChildren();
    const nextActiveItem = this.checkActiveItem(childItems, currentIndex);
    if (nextActiveItem != -1) {
      const id = childItems[nextActiveItem].id;
      this.activateItemById(id);
    } else {
      this.activateCurrent();
    }
  },
  //CODE_CHECK : why declared as Immediately Invoked Function Expression
  validationCheck: (function () {
    const validation = ["duplicateCheck", "emptyValue", "spacesOnBothSides"];
    return function (inputObj, itemChildren, setErrorData = true) {
      for (const eachValidator of validation) {
        const booleanVal = this.executeMethod(
          eachValidator,
          inputObj,
          itemChildren,
          setErrorData
        );
        if (!booleanVal) {
          return false;
        }
      }
      return true;
    };
  })(),
  errorMessageSetter: function (code, value) {
    this.throwEvent('setErrorMessage', {
      code,
      value
    })
    this.setData('errorColor', this.getErrorColor(code));
  },
  makeErrorBoxDirty: function () {
    this.setData('popOverValidate', 'error');
  },
  makeChildActive: function (indx) {
    const itemData = this.getChildren()[indx];
    const item = this.panelCompFinder(this.$node, itemData.id);
    item.component.activateItem(item.querySelector("div"), "leHighlight");
    return false;
  },
  checkActiveItem: function (itemArray, activeIndex) {
    const arrayLen = itemArray.length;
    if (arrayLen > 0) {
      if (activeIndex > arrayLen - 1) {
        return activeIndex - 1;
      } else {
        return activeIndex;
      }
    } else {
      return -1;
    }
  },
  createPanelDirectory(nameValue, fileType, fileContent, restrictActivate = false, errorCallback) {
    const childItems = this.getChildren();
    const isFile = fileType === "file" ? true : false;
    let orginalVal = nameValue;
    if (isFile) {
      nameValue = this.fileExtensionMaker(nameValue);
    }
    if (
      this.validationCheck(
        {
          value: nameValue,
          originalValue: orginalVal,
          type: fileType
        },
        childItems, false, errorCallback
      )
    ) {
      return this.createDirOnDataAndDom(nameValue, fileType, fileContent, childItems, restrictActivate);
    }
    return false;
  },

  renamePanelDirectory(value, comp, id, errorCallback) {
    const component = comp.component;
    const inputValue = value;
    const item = component.getItemData(id);
    let childArray, fileType;
    const editorInstance = component.getEditorInstance(component.data.editorId);

    const forFile = item.type === 'file' || false;
    this.setData("renameFolder", true);
    if (forFile) {
      childArray = component.getChildren();
      fileType = 'file';
      value = component.fileExtensionMaker(value);
    }
    else {
      childArray = component.getParentComp().getChildren();
      fileType = component.getData("item").type;
    }
    // this.$component.set(item,'ext',this.findFileExtension(lyteInput.querySelector("input").value) || "");
    if (item.name !== value) {
      const boolVal = component.validationCheck(
        {
          value: value,
          type: fileType,
          originalValue: inputValue
        },
        childArray, false, errorCallback
      );
      if (boolVal) {
        component.renameDirItem(id, inputValue, true);
      }
    }
    this.setData("renameFolder", false);
  },
  activateItemById(itemId) {
    const createdNode = this.panelCompFinder(this.$node, itemId);
    const isFile = createdNode.getAttribute('is-file');
    if (isFile) {
      this.activateItem(createdNode, "leHighlight", itemId);
    }
    else {
      createdNode.component.activateCurrent();
    }

  },
  //CODE_CHECK : why this function declared as Immediately Invoked Function Expression
  validateDraggable: (function () {
    const validator = {
      parentDropOnChild: function (compData) {
        const dragId = compData.id;
        const nodeEl = compData.node;
        return nodeEl.closest(`[unique-id=${dragId}]`) == null ? false : true;
      },
      childOnSameParent: function (compData) {
        const id = compData.id;
        const nodeEl = compData.node;
        const isChildEl = !(
          nodeEl.component.nodePicker(
            nodeEl,
            `:scope> [unique-id=${id}]`
          ) == null
        );

        return isChildEl;
      }
    };
    return function (dragId) {
      for (let eachKey in validator) {
        const eachValidation = validator[eachKey];
        if (
          eachValidation({
            id: dragId,
            node: this.$node
          })
        ) {
          return false;
        }
      }
      return true;
    };
  })(),
  droppableReset: function () {
    this.setData("initialWaiter", false);
  },
  validateNewItemInDir(inputObj, itemChildren) {
    const validation = ["duplicateCheck", "emptyValue", "spacesOnBothSides"];
    for (const eachValidator of validation) {
      this.executeMethod(
        eachValidator,
        inputObj,
        itemChildren
      );
    }
    return true;
  },
  validationCheck(inputObj, itemChildren, setErrorData = true, errorCallback) {
    const validation = ["duplicateCheck", "emptyValue", "spacesOnBothSides"];
    // return function () {
    try {
      this.validateNewItemInDir(inputObj, itemChildren);
    }
    catch (error) {
      let result = false;
      if (error.errorCode && _led.LEFT_DIRECTORY_ERROR[error.errorCode]) {
        if (errorCallback) {
          if (error.data && error.data.popOverValidate == 'warn') {
            result = true;
          }
          else {
            errorCallback(error);
          }

        }
        if (setErrorData) {
          const errCode = error.errorCode;
          const originalValue = error.data.originalValue;
          const popValid = error.data.popOverValidate;
          if (popValid == 'warn') {
            result = true;
          }
          this.errorMessageSetter(errCode, originalValue);
          this.setPopOverData({
            popOverValidate: popValid
          })
        }
      }
      else {
        console.error(error);
      }
      return result;
    }
    return true;
    // };
  },
  clearWaiter: function () {
    const initalValidationId = this.getData("initialWaiter");
    (initalValidationId || initalValidationId === 0) &&
      clearTimeout(initalValidationId);
  },

  makeMeDroppable: function () {
    $L(this.$node).droppable({
      tolerance: "pointer",
      onEnter: function (draggableEl, droppableEl) {
        const id = draggableEl.getAttribute("id");
        const isAllowable = droppableEl.component.validateDraggable(id);
        if (isAllowable) {
          droppableEl.component.setData(
            "initialWaiter",
            setTimeout(function () {
              droppableEl.component.createFileOpenModal(true);
            }, 1000)
          );
        }
      }.bind(this),
      onLeave: function (draggable, droppable) {
        droppable = droppable.component;
        droppable.clearWaiter();
        droppable.droppableReset();
      }.bind(this),
      onDrop: function (draggable, droppable) {
        const dragId = draggable.getAttribute("id");
        const dragNode = document.querySelector(
          `[unique-id=${dragId}]`
        );
        const isFile = Boolean(dragNode.getAttribute('is-file'));
        const dragComp = dragNode.closest('lyte-editor-directory-panel-item').component;
        const dropComp = droppable.component;
        const dragItem = dragComp.getItemData(dragId);
        const initalValidationId = this.getData("initialWaiter");
        dropComp.clearWaiter();
        if (
          dragNode &&
          (initalValidationId || initalValidationId === 0)
        ) {
          const result = dropComp.validationCheck(
            {
              value: dragItem.name,
              type: dragItem.type,
              originalValue: isFile
                ? dragItem.name.substring(0, dragItem.name.lastIndexOf("."))
                : dragItem.name
            },
            dropComp.getChildren(),
            false
          )
          if (!result) {
            return false;
          }
          const editorInstance = _led.getInstance(dropComp.getData("editorId"));
          const dropItem = dropComp.getData("item");
          const insertedIndex = editorInstance.folder.updateFolderDir(
            dropItem.id,
            dragItem.id
          );
          dragComp.deleteOnDom(dragId);
          // dropComp.createFileOpenModal(true);
          // dropComp.throwEvent('openDirectoryUi');
          dropComp.createDirOnDom(dragItem, dragItem.type, dropComp.getChildren());
          this.getEditorInstance(this.getData("editorId")).pubsub.fire(
            "FORCERENDER"
          );
        }
        droppable.component.droppableReset();
        return false;
      }.bind(this),
      sortCallback: this.sortCallback,
      sortDroppables: true
    });
  },
  makeMeDraggable: function (node) {
    const self = this;
    node.draggable({
      helper: function (dragComponent) {
        const dragEl = document.createElement("span");
        const dragSpan = dragComponent.querySelector(
          ".lyteEditorDirName"
        );
        dragEl.classList.add("lyteEditorDragger");
        let u_id;
        if (Boolean(dragComponent.getAttribute("is-file"))) {
          u_id = dragComponent.getAttribute("unique-id");
          dragComponent = dragComponent.closest(
            "lyte-editor-directory-panel-item"
          );
        }
        else {
          dragComponent = dragComponent.closest(
            "lyte-editor-directory-panel-item"
          );
          u_id = dragComponent.component.getData('uniqueId');
        }
        dragEl.innerText = dragComponent.component
          .getItemData(u_id)
          .name.trim();
        dragEl.style.display = "inline-block";
        dragEl.style.width = dragSpan.offsetWidth + "px";
        dragEl.style.height = dragSpan.offsetHeight + "px";
        dragEl.setAttribute("id", u_id);
        return dragEl;
      },
      onDragStart: function () {
        _led
          .getInstance(self.getData("editorId"))
          .$node.style.setProperty('--cursorData', "default");
      },
      bubbles: false,
      cursorAt: { left: 0, top: -5 },
      threshold: 3,
      appendTo: "body",
      scrollDivY: "lyte-editor-directory-panel .allDirectory",
      scrollSpeed: 1.5,
      onStop: function (element) {
        _led
          .getInstance(self.getData("editorId")).$node
          .style.setProperty('--cursorData', "pointer");
        return false;
      }
    });
  },
  generateError(code, value) {
    const errorObj = this.executeMethod("getErrorLog", code, value);
    const errorMsg = errorObj.error;
    const error = new Error(errorMsg);
    error.errorCode = code;
    return error;
  },
  getLeftDirectoryError(code) {
    const errors = _led.LEFT_DIRECTORY_ERROR;
    return errors[code];
  },
  createErrorString(code, value) {
    let errFormatedStr = this.getLeftDirectoryError(code);
    if (value) {
      return errFormatedStr.replace(/{(\d+)}/g, function (a, indexOnFormatedStr) {
        return value[indexOnFormatedStr];
      })
    }
    return errFormatedStr;
  },
  destroyDraggableByNode(node) {

    $L(node).draggable("destroy");
  },
  destroyDraggableById(id) {
    const node = this.panelCompFinder(this.$node, id);
    this.destroyDraggableByNode(node);
  },
  destroyDraggable() {
    $L(this.$node.querySelector('.le-item-header')).draggable("destroy");
  },
  destroyDroppable() {
    if (this.$node._droppableData) {
      $L(this.$node).droppable("destroy");
    }
  },
  destroyDroppableById(id) {
    const node = this.panelCompFinder(this.$node, id);
    node.droppable("destroy");
  },
  methods: {
    createFile: function (fileType, value, fileContent) {
      let options = { allowSubFolderCreation: true };
      const editorInstance = _led.getInstance(this.data.editorId);

      if (fileType === "file") {
        options.category = "userFile";
        if (fileContent) {
          options.value = fileContent;
        }
        const file = this.executeMethod(
          "createDirectory",
          fileType,
          value,
          options
        );
        if (file.type !== "failure") {
          editorInstance.triggerMethod("onFileCreate", editorInstance.directory.getFileModelObj(file.uniqueId));
          return file.listObjFactory();
        }
      } else {
        const folder = this.executeMethod(
          "createDirectory",
          fileType,
          value,
          options
        );
        if (folder.type !== "failure") {
          editorInstance.triggerMethod("onFolderCreate", editorInstance.directory.getFolderModelObj(folder.uniqueId));
          const obj = folder.listObjFactory();
          obj.children = [];
          return obj;
        }
      }
    },
    createDirectory: function (fileType, value, options) {
      const editorInstance = _led.getInstance(this.data.editorId);
      const id = this.getData("item").id;
      return editorInstance.folder
        .getDirectoryData(id)
        .create[fileType](value, options);
    },

    makeActive: function (id) {
      const editorInstance = _led.getInstance(this.data.editorId);
      const currentlyActiveFile =
        editorInstance.directory.getCurrentlyActiveFile();
      if (!currentlyActiveFile) {
        editorInstance.setData("hideEditor", false);
      }
      if (!currentlyActiveFile || currentlyActiveFile.uniqueId !== id || editorInstance.directory.isMediaFiles(currentlyActiveFile)) { //fix accepting mediaFiles to activate  Eventhough currentlyActivateFile and toActive file are same we need to  load media files is important because data need to assign in media object , (mayBe src data changed,) it is locate in openFile util , but onFileOpen called , --> it is  doubt 
        const callback =
          editorInstance.triggerMethod("onBeforeFileOpen", editorInstance.directory.getFileModelObj(id));
        if (callback === false) {
          return false;
        } else if (callback && callback.then) {
          callback.then(function () {
            editorInstance.directory.openFile(id);
            editorInstance.triggerMethod("onFileOpen", editorInstance.directory.getFileModelObj(id));
          });
        } else {
          editorInstance.directory.openFile(id);
          editorInstance.triggerMethod("onFileOpen", editorInstance.directory.getFileModelObj(id));
        }
        return true;
      }
      return false;
    },
    duplicateCheck: function (inputObj, childrenItem) {
      const inputValue = inputObj.value;
      const inputType = inputObj.type;
      if (childrenItem) {
        for (const eachChild of childrenItem) {
          const childName = eachChild.name;
          if (
            childName.toLowerCase() === inputValue.toLowerCase() &&
            eachChild.type === inputType
          ) {

            const error = this.generateError(409, inputObj.originalValue);
            error.data = {
              originalValue: inputObj.originalValue,
              popOverValidate: 'error'
            }
            // if(setErrorData){
            //   this.errorMessageSetter(409, inputObj.originalValue);
            //   this.setPopOverData({
            //       popOverValidate : 'error'
            //   })
            // }
            // else if(errorCallback){
            //   throw this.generateError(409,inputObj.originalValue);
            // }
            throw error;
          }
        }
      }
      return true;
    },
    emptyValue: function (inputObj, _) {
      const inputValue = inputObj.originalValue;
      const val = inputValue.trim();
      if (val === "") {
        const error = this.generateError(204, inputObj.originalValue);
        error.data = {
          originalValue: inputObj.originalValue,
          popOverValidate: 'error'
        }
        throw error;
      }
      return true;
    },
    spacesOnBothSides: function (inputObj, _) {
      let val = inputObj.originalValue;
      if ((val.trimStart() !== val || val.trimEnd() !== val)) {
        const error = this.generateError(404, inputObj.originalValue);
        error.data = {
          originalValue: inputObj.originalValue,
          popOverValidate: 'warn'
        }
        throw error;

      }
      return true;
    },
    inputFieldBlur: function (node) {
      this.focusOutAction(node, {
        isInputShow: false,
        ext: ""
      });
    },
    getErrorLog: function (code, value = "") {
      let error = "";
      let state = "";
      switch (code) {
        case 204: {
          state = "error";
          break;
        }
        case 409: {
          state = "error";
          break;
        }
        case 404: {
          state = "warn";
          break;
        }
      }
      if (Array.isArray(value)) {
        error = this.createErrorString(code, value);
      }
      else if (!!value) {
        error = this.createErrorString(code, [value]);
      }
      else {
        error = this.createErrorString(code);
      }

      return { error, state };
    }
  },
  actions: {

    valueChange: function (lyteInput, dataName, id) {
      const atRename = this.getData("isRename");
      let value = lyteInput.querySelector("input").value;
      let fileType, childArray, boolVal;
      if (atRename) {
        this.setPopOverData({
          popoverRename: true
        }
        );
        const item = this.getItemData(id);
        const forFile = item.type === 'file' || false;
        if (forFile) {
          childArray = this.getChildren();
          fileType = 'file';
          value = this.fileExtensionMaker(value);
        }
        else {
          childArray = this.getParentComp().getChildren();
          fileType = this.getData("item").type;
        }
        boolVal = true;
        Lyte.Component.set(item, 'ext', this.findFileExtension(lyteInput.querySelector("input").value) || "");
        if (item.name !== value) {
          boolVal = this.validationCheck(
            {
              value: value,
              type: fileType,
              originalValue: lyteInput.querySelector("input").value
            },
            childArray
          );
        }
      } else {
        this.setPopOverData({
          popoverRename: false
        })
        fileType = this.getData("inFileOrFolderCreation");
        let isFile = fileType == "file" ? true : false;
        childArray = this.getChildren();
        if (isFile) {
          value = this.fileExtensionMaker(value);
          this.setData(
            "ext",
            this.findFileExtension(lyteInput.querySelector("input").value)
          );
        }
        boolVal = this.validationCheck(
          {
            value: value,
            type: fileType,
            originalValue: lyteInput.querySelector("input").value
          },
          childArray
        );
      }

      if (boolVal) {
        const warnString = 'warn'
        if (!(this.getData('popOverValidate') == warnString)) {
          this.resetErrorPopOver();
        }
      } else {
        this.setPopOverData({
          popOverValidate: 'error'
        });
      }
      this.setData(dataName, lyteInput.querySelector("input").value);

    }
  }
});
