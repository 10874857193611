_led.addFeature("folder", function () {
  //No I18N
  const directoryData = {};
  let editorInstance;
  const comparator = function (a, b) {
    return a.localeCompare(b);
  };
  const escapeName = _led.improperString.escape;
  const setDirectoryData = function (key, value) {
    directoryData[key] = value;
  };
  const getDirectoryData = function (key) {
    if (key) {
      if (directoryData.hasOwnProperty(key)) {
        return directoryData[key];
      } else {
        return false;
      }
    } else {
      return directoryData;
    }
  };
  const enums = {
    //ignorei18n_start
    MSG_TYPE: ["success", "failure"],
    FILE_HOOKS: {
      ON_BEFORE_SAVE: "ON_BEFORE_SAVE",
      ON_SAVE: "ON_SAVE",
      ON_RENAME: "ON_RENAME",
      ON_MODEL_UPDATE: "ON_MODEL_UPDATE"
    },
    FOLDER_HOOKS: {
      ON_FILE_LIST_CHANGE: "ON_FILE_LIST_CHANGE"
    },
    ORIGINAL : 'original',
    MODIFIED : 'modified'
    //ignorei18n_end
  };
  const type = _led.checkType;
  type.$safeExtend.object("msgObject", {
    //No I18N
    allowable: {
      msg: type.string,
      type: _led.MSG_TYPE
    }
  });
  type.$safeExtend.object("folderOptions", {
    //No I18N
    allowable: {
      valueValidation: type.function,
      fileNameValidation: type.function
    }
  });
  type.$safeExtend.object("fileObject", {
    //No I18N
    required: {
      fileName: type.string,
      value: type.string,
      isActive: type.boolean,
      isDirty: type.boolean
    }
  });
  type.$safeExtend.array("arrayOfFileObject", {
    //No I18n
    every: type.fileObject
  });
  type.$safeExtend.object("folderObject", {
    //No I18N
    allowable: {
      _folderName: type.string,
      createFile: type.function,
      isFileExists: type.function,
      update: type.function,
      renameFile: type.function,
      deleteFile: type.function,
      files: type.arrayOfFileObject,
      validationFor: type.folderOptions
    }
  });
  const removeExtensionFrom = function (fileName) {
    return fileName.substring(0, fileName.lastIndexOf("."));
  };
  const ID_FORMATS = {
    //ignorei18n_start
    dir: {
      format: "XXX-XX-XXX",
      constructFn: function (format, id) {
        return _led.changeCase.toKebab(id) + "-" + format;
      }
    }
    //ignorei18n_end
  };
  const generateIdFor = {};
  const uuid = _led.uuid;
  for (let id in ID_FORMATS) {
    const value = ID_FORMATS[id];
    generateIdFor[id] = uuid[id] || uuid.createFormat(
      value.format,
      id,
      value.constructFn,
      value.constructEverytime
    );
  }

  function File(name, options) {
    const triggerCallback = function (hookName) {
      const args = Array.from(arguments).slice(1);
      const callbacksToExecute = registeredCallbacks[hookName];
      callbacksToExecute.forEach(function (callback) {
        callback.apply(API, args);
      });
    };
    const asynchronousCallback = function (hookName) {
      const args = Array.from(arguments).slice(1);
      const callbacksToExecute = registeredCallbacks[hookName];
      const promises = callbacksToExecute.map(function (callback) {
        return new Promise(function (resolve, reject) {
          const output = callback.apply(API, args);
          if (output === undefined || output === true) {
            resolve();
          } else if (output instanceof Promise) {
            output
              .then(function () {
                resolve();
              })
              .catch(function (error) {
                reject(error);
              });
          } else {
            reject("Return false");
          }
        });
      });
      return Promise.all(promises);
    };
    const fileList = function () {
      return {
        name: this.name,
        type: this.type,
        id: this.uniqueId,
        ext: this.ext
      };
    };
    const registeredCallbacks = {};
    for (let hookName in enums.FILE_HOOKS) {
      registeredCallbacks[hookName] = [];
    }
    const triggerCallbacksForMetaUpdate = function (key, value) {
      const keyHookMap = {
        model: enums.FILE_HOOKS.ON_MODEL_UPDATE
      };
      if (keyHookMap[key]) {
        triggerCallback(keyHookMap[key], value);
      }
    };
    const meta = {};
    const metaProxyHandler = {
      set: function (target, key, value) {
        target[key] = value;
        triggerCallbacksForMetaUpdate(key, value);
      }
    };
    const API = {
      uniqueId: generateIdFor.dir(),
      name: name,
      listObjFactory: fileList,
      internal: options.inbuild || false,
      parentDir: options.parentDir,
      get ext() {
        return this.name.split(".").pop();
      },
      value: options.value || "", //No I18n
      isActive: options.activeState || false,
      isDirty: options.dirtyState || false,
      programmaticSave: false,
      lastSavedValue: options.value || "", //No I18n
      mutableValues: {},
      _registeredCallbacks: registeredCallbacks,
      meta: new Proxy(meta, metaProxyHandler),
      rangeConfig: {}
    };
    if(options.hasOwnProperty(enums.ORIGINAL)){
      API.originalValue = options[enums.ORIGINAL];
    }
    Object.defineProperties(API, {
      path: {
        get: function () {
          const path = [API.name];
          let parentDir = API.parentDir;
          while (directoryData[parentDir]) {
            let folder = directoryData[parentDir];
            path.push(folder.name);
            parentDir = folder.parentDir;
          }
          path.pop();
          path.reverse();
          return path.join("/");
        }
      },
      parentFolder : {
        get : function() {
          let parentDir = options.parentDir;
          if (directoryData[parentDir]) {
            return directoryData[parentDir];
          }
        }
      },
      baseFolderName: {
        get: function () {
          let parentDir = options.parentDir;
          if (directoryData[parentDir]) {
            let folder = directoryData[parentDir];
            return folder.name;
          }
          return "";
        }
      }
    });
    const methods = {
      type: "file", //No I18n
      category: options.category || null,
      belongsTo: options.belongsTo || null,
      activate: function () {
        Lyte.Component.set(API, "isActive", true);
        return API;
      },
      deactivate: function () {
        Lyte.Component.set(API, "isActive", false);
        return API;
      },
      setValue: function (value,isOriginal, updateModel) {
        if (type.string(value)) {
          if(updateModel && API.meta.model) {
            const model = API.meta.model;
            if(value != model.getValue()) {
              model.setValue(value);
            }
            editorInstance.directory.setDirtyStateOfFile(API.uniqueId, false);
          }
          if(isOriginal==true){
             API.originalValue = value;
          }
          else{
             API.value = value;
          }
          return API;
        } else {
          _led.throw.error(_led.ERROR.TYPE_MUST_BE, "string"); //No I18n
        }
      },
      updateMutableValues: function (mutableValues) {
        if (type.object(mutableValues)) {
          API.mutableValues = mutableValues;
        } else {
          _led.throw.error(_led.ERROR.TYPE_MUST_BE, "object"); //No I18n
        }
      },
      rename: function (newNameData) {
        const itemId = this.uniqueId;
        const itemSubTree = getDirectoryData(itemId);
        deleteFolderDir(itemId);
        this.name = newNameData.value;
        return insertFolderDir(this.parentDir, itemSubTree);
      },
      delete: function () {
        const parentFolder = API.get(API.parentFolderPath);
        delete parentFolder.dirContent[API.name];
        return API;
      },
      setDirtyStateAs: function (state) {
        if (type.boolean(state)) {
          Lyte.Component.set(API, "isDirty", state);
          return API;
        }
      },
      syncRangeRestrictions: function () {
        const model = API.meta.model;
        const rangeConfig = API.rangeConfig;
        if (model) {
          const currentValue = model.getValue();
          const lastSavedValue = API.lastSavedValue;
          if (currentValue !== lastSavedValue) {
            model.setValue(lastSavedValue);
            const escapeName = _led.Utils.improperString.escape;
            if (
              model._isLyteRestrictedModel &&
              rangeConfig &&
              rangeConfig.editableRanges
            ) {
              if (API.category != "dist") {
                _led.localStorage.merge({
                  ["directoryList." +
                    escapeName(API.uniqueId) +
                    ".rangeConfig"]: rangeConfig //No I18n
                });
              }
              model.updateRestrictions(rangeConfig.editableRanges);
            }
            API.save(true, true);
          }
        }
      },
      save: function (programmaticSave, saveWithoutCallbacks) {
        return new Promise(function (resolve, reject) {
          const promise = asynchronousCallback(
            enums.FILE_HOOKS.ON_BEFORE_SAVE,
            API.value,
            API
          );
          promise
            .then(function () {
              API.lastSavedValue = API.value;
              if (!saveWithoutCallbacks) {
                API.programmaticSave = programmaticSave;
                asynchronousCallback(
                  enums.FILE_HOOKS.ON_SAVE,
                  API.lastSavedValue,
                  API
                )
                  .then(function () {
                    resolve();
                  })
                  .catch(function (error) {
                    reject(error);
                  });
                API.programmaticSave = false;
              }
              editorInstance.directory.setDirtyStateOfFile(API.uniqueId, false);
            })
            .catch(function (error) {
              reject(error);
            });
        });
      },
      resetDirtyChanges: function () {
        const model = API.meta.model;
        if (model) {
          model.setValue(API.lastSavedValue);
        }
        editorInstance.directory.setDirtyStateOfFile(API.uniqueId, false);
      },
      addHook: function (hookName, callback) {
        if (registeredCallbacks.hasOwnProperty(hookName)) {
          registeredCallbacks[hookName].push(callback);
        } else {
          _led.devLog.warn("Invalid hook :" + hookName); //No I18n
        }
      },
      getCurrentState: function () {
        const keyToIncludeInState = [
          //ignorei18n_start
          "parentFolderPath",
          "baseFolderName",
          "name",
          "ext",
          "value",
          "isActive",
          "isDirty",
          "lastSavedValue",
          "mutableValues",
          uniqueId
          //ignorei18n_end
        ];
        const state = keyToIncludeInState.reduce(function (acc, key) {
          acc[key] = API[key];
          return acc;
        }, {});
        return state;
      },
      setRangeConfig: function (config) {
        API.rangeConfig = config;
      },
      dispose: function () {
        // Todo : Done this temporarily, have to remove any values which can potentially cause memory leak
        return null;
      },
      ignoreBlueprints: function () {
        API.noBlueprints = true;
      }
    };
    _led.defineProp.call(API, _led.DESCRIPTOR_CODES[4], methods);
    if (type.string(API.name)) {
      return API;
    } else {
      _led.throw.error(
        _led.ERROR.TYPE_MUST_BE,
        "string",
        "name",
        "Found : " + API.name
      ); //No I18N
    }
  }

  function Folder(name, options) {
    const fallbackFn = function () {
      return true;
    };
    const defaults = {
      folderNameValidation: fallbackFn,
      fileNameValidation: fallbackFn,
      valueValidation: fallbackFn
    };
    for (let key in defaults) {
      if (!options.hasOwnProperty(key)) {
        options[key] = defaults[key];
      }
    }
    const create = {};
    const createFile = function (name, options) {
      const fileOptions = Object.assign({}, options, {
        parentDir: API.uniqueId
      });
      const fileObject = new File(name, fileOptions);
      fileObject.addHook(
        enums.FILE_HOOKS.ON_RENAME,
        function (newName, oldName, file) {
          this.dirContent[newName] = fileObject;
          delete this.dirContent[oldName];
          // We have to call update directory from here
          // The old helper is not yet removed
          // triggerCallback(enums.FOLDER_HOOKS.ON_FILE_LIST_CHANGE);
        }.bind(API)
      );
      fileObject.addHook(
        enums.FILE_HOOKS.ON_BEFORE_SAVE,
        function (newValue, file) {
          return new Promise(function (resolve, reject) {
            resolve();
          });
        }.bind(API)
      );
      orderInsertion(API, fileObject.name, comparator, true);
      setDirectoryData(fileObject.uniqueId, fileObject);
      API.dirContent[name] = fileObject;
      return fileObject;
    };
    const appendFolder = function(folder) {
      orderInsertion(API, folder.name, comparator, false);
      API.dirContent[folder.name] = folder;
      folder.parentDir = API.uniqueId;
      setDirectoryData(folder.uniqueId, folder);
    };
    const deleteFolder = function(folder) {
      delete API.dirContent[folder.name];
    };
    create.file = createFile;
    if (options.allowSubFolderCreation) {
      const createFolder = function (name, subFolderOptions) {
        subFolderOptions = Object.assign({}, options, subFolderOptions, {
          parentDir: API.uniqueId,
          category: API.name
        });
        const subFolder = new Folder(name, subFolderOptions);
        orderInsertion(API, subFolder.name, comparator, false);
        API.dirContent[name] = subFolder;
        setDirectoryData(subFolder.uniqueId, subFolder);
        return subFolder;
      };
      create.folder = createFolder;
    }
    const rename = function (newNameData) {
      if (API.validationFor.folderName(newNameData.value)) {
        const itemId = API.uniqueId;
        const itemSubTree = getDirectoryData(itemId);
        deleteFolderDir(itemId);
        API.name = newNameData.value;
        return insertFolderDir(API.parentDir, itemSubTree);
      }
    };
    const listObjFactory = function () {
      return {
        type: this.type,
        name: this.name,
        id: this.uniqueId
      };
    };

    const list = function () {
      const contents = [];
      for (let key of API.order) {
        const value = API.dirContent[key];
        const valueObject = value.listObjFactory();
        if (value.type === "folder") {
          Object.assign(valueObject, {
            children: value.list()
          });
        }
        contents.push(valueObject);
      }
      return contents;
    };
    const getAllFileNames = function () {
      const fileNames = [];
      for (let key in API.dirContent) {
        const value = API.dirContent[key];
        fileNames.push(removeExtensionFrom(value.name));
      }
      return fileNames;
    };
    const checkForPathExistence = function (relPath) {
      if (API.get(relPath)) {
        return true;
      }
      return false;
    };
    const getDirContent = function() {
      return API.dirContent;
    };
    const getDirectFile = function (fileName) {
     return API.dirContent[fileName];
    };
    const isFileExists = function (fileName) {
      return API.dirContent.hasOwnProperty(fileName);
    };
    const getCurrentState = function () {
      const state = {};
      for (let key in API.dirContent) {
        state[key] = API.dirContent[key].getCurrentState();
      }
      return state;
    };
    const dispose = function () {
      for (let key in API.dirContent) {
        API.dirContent[key].dispose();
        delete API.dirContent[key];
      }
    };
    const internals = {
      _options: options,
      name: name,
      dirContent: {},
      order: [],
      fileStart: 0,
      parentDir: options.parentDir,
      newEntityStructure: options.newEntityStructure,
      canFolderBeNested: options.allowSubFolderCreation,
      uniqueId: generateIdFor.dir()
    };
    const API = Object.create(internals);
    Object.defineProperties(API, {
      path: {
        get: function () {
          const path = [API.name];
          let parentDir = API.parentDir;
          while (directoryData[parentDir]) {
            let folder = directoryData[parentDir];
            path.push(folder.name);
            parentDir = folder.parentDir;
          }
          path.pop();
          path.reverse();
          return path.join("/");
        }
      },
      parentFolder : {
        get : function() {
          let parentDir = options.parentDir;
          if (directoryData[parentDir]) {
            return directoryData[parentDir];
          }
        }
      },
      baseFolderName: {
        get: function () {
          let parentDir = options.parentDir;
          if (directoryData[parentDir]) {
            let folder = directoryData[parentDir];
            return folder.name;
          }
          return "";
        }
      }
    });
    const methods = {
      type: "folder", //No I18n
      create,
      rename,
      internal: options.inbuild || false,
      getDirContent : getDirContent,
      getFile : getDirectFile,
      list,
      listObjFactory,
      getAllFileNames,
      hasPath: checkForPathExistence,
      isFileExists,
      getCurrentState,
      dispose,
      validationFor: {
        fileName: options.fileNameValidation,
        value: options.valueValidation,
        folderName: options.folderNameValidation
      },
      appendFolder : appendFolder,
      deleteFolder : deleteFolder
    };
    return _led.defineProp.call(API, _led.DESCRIPTOR_CODES[4], methods);
  }

  const userDirectory = function (json) {
    //CODE_CHECK : getrootDirectory which is present in same scope you can use API.
    const fileCreate = API.getRootDirectory().create.file;
    const folderCreate = API.getRootDirectory().create.folder;
    const options = { allowSubFolderCreation: true };
    const recurseDirectoryProvide = function (
      jsonArray,
      fileMake,
      folderMake,
      dirContent,
      parent
    ) {
      jsonArray.forEach(
        function (eachDir) {
          const dirName = eachDir.name;
          if (!dirContent[dirName]) {
            if (eachDir.children) {
              options.parentDir = parent.uniqueId;
              const subFolder = folderMake(dirName, options);
              const fileCreate = subFolder.create.file;
              const folderCreate = subFolder.create.folder;
              recurseDirectoryProvide(
                eachDir.children,
                fileCreate,
                folderCreate,
                subFolder.dirContent,
                subFolder
              );
            } else {
              let  options;
              if(eachDir.hasOwnProperty(enums.ORIGINAL) && eachDir.hasOwnProperty(enums.MODIFIED)){
                options = {
                  category : 'userFile',
                  value : eachDir[enums.MODIFIED] || '',
                  [enums.ORIGINAL] : eachDir[enums.ORIGINAL] || ''
                }
              }
              else{
                options = {
                  category : 'userFile',
                  value : eachDir.fileContent || eachDir.value || '',
                  dirtyState : eachDir.isDirty || false
                }
              }
              fileMake(dirName, options);
            }
          }
        }.bind(this)
      );
    };
    recurseDirectoryProvide(
      json,
      fileCreate,
      folderCreate,
      API.dirContent,
      API
    );
  };
   const deleteAllDirectoroyData = function(root){
      const rootUniqueId = root.uniqueId;
      const dirContent = root.dirContent;
      if(dirContent && root.type=='folder')
      {
            for(let eachKey in dirContent)
            {
              deleteAllDirectoroyData(dirContent[eachKey]);
            }
            if(rootUniqueId!==rootDir.uniqueId)
            {
              deleteFolderDir(rootUniqueId,true);
            }
      }
      else{
        deleteFolderDir(rootUniqueId,true);
      }
  }
  const resetUserDirectory = function(){
      const directoryData = getDirectoryData();
      const rootId = rootDir.uniqueId;
      deleteAllDirectoroyData(rootDir);
      rootDir = new Folder("root", { allowSubFolderCreation: true }); //No I18n
      rootDir.root = true;
       setDirectoryData(rootDir.uniqueId, rootDir);
  }
   const generatePath = function(id)
  {
    const pathArr = [];
    let breakLoop = true;
    while(breakLoop)
    {
        const data = getDirectoryData(id);
        breakLoop = data.root!=true;
        if(breakLoop)
        {
            pathArr.push(data.name);
            id = data.parentDir;
        }
    }
    return pathArr.reverse();
  }
  const generatePathUrl = function(id){
    return generatePath(id).join('/');
  };
  const orderInsertion = function (
    parentDir,
    dirItemName,
    comparator,
    isFile = true
  ) {
    let start, end;
    if (!isFile) {
      start = 0;
      end = parentDir.fileStart - 1;
      ++parentDir.fileStart;
    } else {
      start = parentDir.fileStart;
      end = parentDir.order.length - 1;
    }
    return insertIntoSortedArray(
      parentDir.order,
      dirItemName,
      comparator,
      start,
      end
    );
  };
  const orderDeletion = function (parentDir, itemName, isFile = true) {
    const inputArr = parentDir.order;
    const index = inputArr.indexOf(itemName);
    index != -1 &&
      (function () {
        inputArr.splice(index, 1);
        !isFile && --parentDir.fileStart;
      })();
  };
  const insertIntoSortedArray = function (
    inputArray,
    dirItem,
    comparator,
    start,
    end
  ) {
    if (!inputArray.length == 0) {
      for (; start <= end; start++) {
        const eachDir = inputArray[start];
        const compValue = comparator(eachDir, dirItem);
        if (Number.isInteger(compValue) && compValue > 0) {
          inputArray.splice(start, 0, dirItem);
          return start;
        }
      }
      inputArray.splice(start, 0, dirItem);
      return start;
    } else {
      inputArray.push(dirItem);
      return inputArray.length - 1;
    }
  };
  const setEditorInstance = function (editor) {
    editorInstance = editor;
  };
  const getEntry = function (relPath, API) {
    API = API || editorInstance.folder;
    const temp = relDirFetch.call(API, relPath);
    if (temp) {
      return temp;
    }
    return false;
  };
  const checkForPathExistence = function (uuid) {
    if (getEntry(uuid)) {
      return true;
    }
    return false;
  };
  const relDirFetch = function (path) {
    //If path is empty 
    return path.split("/").reduce((acc, value) => {
      return acc.dirContent[value];
    }, this.getRootDirectory());
  };
  const getState = function (name) {
    const object = {};
    let dirContent = relDirFetch.call(this, name);
    for (const name in dirContent.dirContent) {
      const fileObject = dirContent.dirContent[name];
      object[escapeName(fileObject.uniqueId)] = {
        language: fileObject.ext,
        type: fileObject.category,
        value: fileObject.value,
        uniqueId: fileObject.uniqueId,
        lastSavedValue: fileObject.lastSavedValue,
        isActive: fileObject.isActive,
        isDirty: fileObject.isDirty,
        name: name,
        rangeConfig: fileObject.rangeConfig
      };
    }
    return object;
  };
  const replaceFolderDir = function (delname, newName, parentDir) {
    const dirObj = parentDir.dirContent;
    for (let eachDirKey in dirObj) {
      if (eachDirKey === delname) {
        const dirvalue = dirObj[eachDirKey];
        delete dirObj[eachDirKey];
        dirObj[newName] = dirvalue;
        break;
      }
    }
  };
   const fetchDir = function (path, skipError) {
    //If path is empty 
    if(path==='/')
    {
        return this.getRootDirectory();
    }
    if(path.at(0)==='/')
    {
      path = path.slice(1);
    }
    return path.split("/").reduce((acc, value) => {
      const childItem =  acc.dirContent[value];
      if(!childItem && !skipError)
      {
        const error = new Error('no directory found');  //No I18N
        error.errorCode = 400;
        throw error;
      }
      return childItem;
    }, this.getRootDirectory());
  };
  const deleteFolderDir = function (itemId, isDispose) {
    const parentFolder = editorInstance.directory.getParentDir(itemId);
    const dirData = API.getDirectoryData();
    const item = dirData[itemId];
    const itemName = item && item.name;
    if (parentFolder && itemName) {
      const parentFolderChildren = parentFolder.dirContent;
      delete parentFolderChildren[itemName];
      if (isDispose && item.type == "file" && item.meta.model) {
        item.meta.model.dispose();
      }
      orderDeletion(parentFolder, item.name, item.type === "file");
      delete dirData[itemId];
    } else {
      console.warn("No such directory are present");
    }
  };
  const insertFolderDir = function (parentId, item) {
    setDirectoryData(item.uniqueId, item);
    const parentDir = getDirectoryData(parentId);
    parentDir.dirContent[item.name] = item;
    item.parentDir = parentId;
    return orderInsertion(
      parentDir,
      item.name,
      comparator,
      item.type == "file"
    );
  };
  const updateFolderDir = function (newParentId, itemId) {
    const itemSubTree = getDirectoryData(itemId);
    deleteFolderDir(itemId);
    return insertFolderDir(newParentId, itemSubTree);
  };
  
  let rootDir = new Folder("root", { allowSubFolderCreation: true }); //No I18n
  rootDir.root = true;
  setDirectoryData(rootDir.uniqueId, rootDir);
  const getRootDir = function () {
    return rootDir;
  };
  const disposeFolderEntity = function(){
    for(let eachId in ID_FORMATS){
      const uuid =_led.uuid;
      uuid[eachId].dispose();
    }
  };
  const createNewInstance = function(folderName, options) {
    return new Folder(folderName, options);
  };
  const manipulator = {
    createNewInstance : createNewInstance,
    userDirectory,
    resetUserDirectory,
    dirContent: {},
    getRootDirectory: getRootDir
  };

  const API = Object.create(manipulator);
  const methods = {
    getEntry: getEntry,
    getDirectoryData,
    setDirectoryData,
     relDirFetch,
    hasPath: checkForPathExistence,
    getState: getState,
   
    setEditorInstance: setEditorInstance,
    replaceFolderDir: replaceFolderDir,
    deleteFolderDir: deleteFolderDir,
    updateFolderDir,
    insertFolderDir,
    getDirByPath : fetchDir,
    dispose : disposeFolderEntity,
    generatePath,
    generatePathUrl
  };
  return _led.defineProp.call(API, _led.DESCRIPTOR_CODES[6], methods);
});
